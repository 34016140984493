import { useParams } from "react-router-dom";
import { CentralContainer, ContainerHeader } from "../conponents/Containers";
import { Margins } from "../conponents/Margins";
import useData from "../hooks/useData";
import { useRef, useState } from "react";
import { SearchInput } from "../conponents/Input";

const GroupCardCategory = ({ x, index }) => {
    const { name, description, items } = x
    const groupRef = useRef()

    return (
        <div key={x.key} className="flex flex-col items-start p-2 shadow shadow-primary border border-transparent hover:border-color5 cursor-pointer relative" ref={groupRef}>
            <div className="flex items-center">
                <span className="text-sm pr-2">#{index + 1}</span>
                <span className="text-green">{name}</span>
            </div>
            <div className="flex w-full text-color5">
                <div className="flex-grow flex items-center italic px-3 text-sm h-6">{description}</div>
                <div className="flex w-auto items-center italic px-3 text-xs h-6">{items ? items : 0} items(s)</div>
            </div>
        </div>
    )
}

export default function Group() {
    const { username } = useParams()
    const [showGroup, setShowGroup] = useState('CAT')
    const { categoryList } = useData()
    const [categoryKey, setCategoryKey] = useState('')

    const filteredCategory = categoryList?.filter(x => { return x.name.toLowerCase().includes(categoryKey.toLowerCase()) || x.description.toLowerCase().includes(categoryKey.toLowerCase()) })

    return (
        <Margins>
            <div className="flex flex-col">
                <CentralContainer>
                    <ContainerHeader page={showGroup === 'CAT' ? 'Category' : 'SubCategory'} username={username} showBack={true} />
                    <div className="flex p-2">
                        <button className={"flex-1 p-2 font-semibold " + (showGroup === 'CAT' ? 'bg-plant text-primary' : 'bg-primary text-color5')} onClick={() => setShowGroup('CAT')}>Category</button>
                    </div>
                    {
                        showGroup === 'CAT'
                            ? <>
                                <SearchInput id={'category'} value={categoryKey} onChange={(e) => setCategoryKey(e.target.value)} />
                                <div className="flex flex-col py-1">
                                    {
                                        filteredCategory?.length > 0
                                            ? filteredCategory.map((x, index) => {
                                                return <GroupCardCategory type={'category'} key={x.id} x={x} index={index} />
                                            })
                                            : <div className="flex w-full justify-center text-sm italic">No data to show</div>
                                    }
                                </div>
                            </>
                            : null
                    }
                </CentralContainer>
            </div>
        </Margins>
    )
}