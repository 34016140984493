import { Fragment, useRef, useState } from "react"
import { AddPhoto } from "../assets/Icons"
import useData from "../hooks/useData"
import { storage, giveCurrentDateTime } from '../hooks/firebaseConfig'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { ImageSpalsh, Spalsh } from "./Splash";
import { S3_BUCKET, s3 } from "../hooks/S3BucketConfig";

const BASEURL = process.env.REACT_APP_URL
const IMG_PATH = process.env.REACT_APP_IMG_PATH

export const ImageInput = ({ image, setImage }) => {
    //NOT USED 
    const imageInputRef = useRef()
    const { store, setStore, storeAction } = useData()
    const [loading, setLoading] = useState(true)
    const [uploading, setUploding] = useState({ status: false, percentage: 0 })
    const img = image ? typeof image === 'string' ? image : URL.createObjectURL(image) : ''
    const imgClass = "w-full h-full " + (uploading.status === true ? 'opacity-10 ' : '') + (loading ? 'hidden ' : '')

    const handleImage = async () => {
        var file = imageInputRef.current.files[0]
        if (!file) { return }

        setImage(file)

        //Start uploading
        setUploding(c => { return { ...uploading, status: true } })

        const fileName = `R-${store.id}/Items/Item_${giveCurrentDateTime()}`
        const publicUrl = `https://${S3_BUCKET}.s3.amazonaws.com/${fileName}`

        const params = {
            Bucket: S3_BUCKET,
            Key: fileName,
            Body: file,
        }

        var upload = s3.putObject(params)
            .on("httpUploadProgress", (evt) => {
                const per = parseInt((evt.loaded * 100) / evt.total)
                setUploding((prevState) => ({ ...prevState, percentage: per }))
            })
            .promise()

        await upload.then(async (err, data) => {
            console.log(publicUrl)
            await setStore(c => { return { ...store, image: publicUrl } })
            await storeAction('UPDATE_PROFILE', publicUrl)
            await setUploding(c => { return { ...uploading, status: false, percentage: 0 } })
        })
    }

    return (
        <div className="">
            <label htmlFor='img-input' className="flex justify-center items-center relative overflow-hidden cursor-default md:cursor-pointer w-[12rem] h-[12rem] rounded-full -mt-[6rem] mx-auto border bg-secondary">
                {
                    !loading
                        ? image
                            ? <div className="relative w-full flex justify-center items-center">
                                {loading && <ImageSpalsh />}
                                <img onLoad={() => setLoading(false)} onError={() => setLoading(false)} src={img} className={imgClass} alt={'image'} />

                                {uploading.status === true && <UploadProgress per={uploading.percentage} />}
                            </div>
                            : null
                        : <div className="p-6 w-[10rem] h-[10rem]"><AddPhoto /></div>

                }
                <input id='img-input' type="file" className="w-0 hidden absolute inset-0" accept="image/*" onChange={handleImage} ref={imageInputRef} />
            </label>
        </div>
    )
}

export const CoverInput = ({ cover, setCover }) => {
    const imageInputRef = useRef()
    const { store, setStore, storeAction } = useData()
    const [loading, setLoading] = useState(true)
    const [uploading, setUploding] = useState({ status: false, percentage: 0 })
    const img = cover ? typeof cover === 'string' ? `${cover}` : URL.createObjectURL(cover) : ''
    const imgClass = "w-full h-full object-fill " + (uploading.status === true ? 'opacity-10 ' : '') + (loading ? 'hidden ' : '')

    const handleImage = async () => {
        var file = imageInputRef.current.files[0]
        if (!file) { return }

        setCover(file)

        //Start uploading
        await setUploding(c => { return { ...uploading, status: true } })

        const fileName = `R-${store.id}/Cover/Cover_${giveCurrentDateTime()}`
        const publicUrl = `https://${S3_BUCKET}.s3.amazonaws.com/${fileName}`

        const params = {
            Bucket: S3_BUCKET,
            Key: fileName,
            Body: file,
        }

        var upload = s3.putObject(params)
            .on("httpUploadProgress", (evt) => {
                const per = parseInt((evt.loaded * 100) / evt.total)
                setUploding((prevState) => ({ ...prevState, percentage: per }))
            })
            .promise()

        await upload.then(async (err, data) => {
            console.log(publicUrl)
            await setStore(c => { return { ...store, cover: publicUrl } })
            await storeAction('UPDATE_COVER', publicUrl)
            await setUploding(c => { return { ...uploading, status: false, percentage: 0 } })
        })
    }

    return (
        <Fragment>
            {/* <Message message={errorMessage} state={errorState} setState={setErrorState} /> */}
            <label htmlFor='cover-input' className="flex justify-center items-center relative bg-primary overflow-hidden cursor-default md:cursor-pointer w-full h-[12rem]">
                {loading && <ImageSpalsh />}
                {
                    cover
                        ? <div className="relative flex justify-center items-center border-b">
                            <img onLoad={() => setLoading(false)} onError={() => setLoading(false)} src={img} className={imgClass} alt="image" />
                            {uploading.status === true && <UploadProgress per={uploading.percentage} />}
                        </div>
                        : <div className="p-6 w-[10rem] h-[10rem]"><AddPhoto /></div>
                }
                <input id='cover-input' type="file" className="w-0 hidden absolute inset-0" accept="image/*" onChange={handleImage} ref={imageInputRef} />
            </label>
        </Fragment>
    )
}

export const MenuImage = ({ menu, setMenu, setIsUploading }) => {
    const imageInputRef = useRef()
    const { product, store, hideSplash } = useData()

    const [uploading, setUploding] = useState({ status: false, percentage: 0 })
    const [load, setLoad] = useState(true)

    const imgaeSrc = menu ? typeof menu === 'string' ? `${menu}` : URL.createObjectURL(menu) : ''
    const imgClass = "w-full h-full " + (uploading.status === true ? 'opacity-10 ' : '') + (load ? 'hidden ' : '')

    const handleImage = async () => {
        var file = imageInputRef.current.files[0]
        if (!file) { return }
        setIsUploading(true)
        setMenu(file)

        // //Start uploading
        setUploding(c => { return { ...uploading, status: true } })

        const fileName = `R-${store.id}/Items/Item_${giveCurrentDateTime()}`
        const publicUrl = `https://${S3_BUCKET}.s3.amazonaws.com/${fileName}`

        const params = {
            Bucket: S3_BUCKET,
            Key: fileName,
            Body: file,
        }

        var upload = s3.putObject(params)
            .on("httpUploadProgress", (evt) => {
                const per = parseInt((evt.loaded * 100) / evt.total)
                setUploding((prevState) => ({ ...prevState, percentage: per }))
            })
            .promise()

        await upload.then(async (err, data) => {
            console.log(publicUrl)
            await setMenu(publicUrl)
            await setUploding(c => { return { ...uploading, status: false, percentage: 0 } })
            setIsUploading(false)
        })
    }

    const onLoadImage = () => {
        setLoad(false)
        hideSplash(1000)
    }

    return (
        <>
            <label htmlFor='menu-input' className="flex justify-center items-center relative bg-primary overflow-hidden cursor-default md:cursor-pointer w-[10rem] h-[10rem] rounded-full">
                {load && <ImageSpalsh />}
                {
                    menu
                        ? <div className="relative flex justify-center items-center">
                            <img onLoad={onLoadImage} onError={onLoadImage} src={imgaeSrc} className={imgClass} alt="image" />
                            {uploading.status === true && <UploadProgress per={uploading.percentage} />}
                        </div>
                        : <div className="p-6 w-[10rem] h-[10rem]">
                            <AddPhoto setLoad={(e) => setLoad(false)} />
                        </div>
                }
                <input id='menu-input' type="file" className="w-0 hidden absolute inset-0" accept="image/*" onChange={handleImage} ref={imageInputRef} />
            </label>
        </>
    )
}

const UploadProgress = ({ per }) => {

    per = per + '%'
    return (
        <div className="px-1 w-full rounded-md absolute">
            <div className="w-full bg-color5 z-[10] rounded-md">
                <div className="bg-color7 text-xs font-medium text-primary text-center p-0.5 leading-none rounded-full" style={{ width: per }}></div>
            </div>
        </div>
    )
}