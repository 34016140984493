import { useEffect } from "react"
import useData from "../hooks/useData"
import '../assets/css/privacy.css'

export default function Privacy() {
    const { hideSplash } = useData()

    useEffect(() => {
        hideSplash()
    })
    return (
        <div className="privacy bg-primary p-5 text-white-gray overflow-auto">
            <h1 className="text-plant font-bold text-xl">Privacy Policy for Rapid</h1>
            <div className="section">
                <p><strong>Effective Date:</strong> 21-September-2023</p>
                <p><strong>Introduction</strong></p>
                <p>Welcome to Rapid. At Rapid, we value your privacy and are committed to protecting your personal information.
                    This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information when you
                    use our services.</p>
                <p>By using our website, mobile application, or any other service provided by Rapid, you agree to the terms and
                    practices described in this Privacy Policy. If you do not agree with this Privacy Policy, please do not use
                    our services.</p>
            </div>

            <div className="section">
                <p><strong>Information We Collect</strong></p>
                <p>We may collect the following types of personal information when you use our services:</p>
                <ul>
                    <li>Personal Information: This may include your name, contact information (such as email address and phone
                        number), and delivery address.</li>
                    <li>Delivery Information: Information related to your delivery orders, including order details, delivery
                        locations, and delivery preferences.</li>
                    <li>Payment Information: We collect payment information, such as credit card details, to process your payment
                        for our services.</li>
                    <li>Location Information: We may collect your device's location information if you enable location services
                        on our mobile application.</li>
                    <li>Usage Information: We collect data about how you use our services, including your interactions with our
                        website and mobile application, IP address, browser type, and referring pages.</li>
                </ul>
            </div>

            <div className="section">
                <p><strong>How We Use Your Information</strong></p>
                <p>We may use your personal information for the following purposes:</p>
                <ul>
                    <li>Provide Services: To fulfill delivery orders, communicate with you, and provide customer support.</li>
                    <li>Improve Services: To enhance our services, troubleshoot issues, and analyze user behavior.</li>
                    <li>Marketing: To send you promotional materials and updates about our services, subject to your
                        preferences.</li>
                    <li>Legal and Security: To comply with legal obligations, protect our rights and interests, and ensure the
                        security of our services.</li>
                </ul>
            </div>

            <div className="section">
                <p><strong>Disclosure of Your Information</strong></p>
                <p>We may share your personal information with the following parties:</p>
                <ul>
                    <li>Service Providers: We may share your information with third-party service providers who assist us in
                        providing and improving our services.</li>
                    <li>Business Partners: We may share your information with our business partners to offer joint promotions or
                        services.</li>
                    <li>Legal Requirements: We may disclose your information when required by law or in response to legal
                        requests, such as court orders or government inquiries.</li>
                </ul>
            </div>

            <div className="section">
                <p><strong>Security of Your Information</strong></p>
                <p>We take reasonable measures to protect your personal information from unauthorized access, disclosure, or
                    alteration. However, no data transmission over the internet or storage system is completely secure, and we
                    cannot guarantee the absolute security of your information.</p>
            </div>

            <div className="section">
                <p><strong>Your Choices</strong></p>
                <p>You have certain rights regarding your personal information:</p>
                <ul>
                    <li>Access and Correction: You can request access to and correction of your personal information in our
                        records.</li>
                    <li>Opt-Out: You can opt-out of receiving promotional emails or marketing communications from us by
                        following the instructions provided in the emails or by contacting us.</li>
                </ul>
            </div>

            <div className="section">
                <p><strong>Changes to this Privacy Policy</strong></p>
                <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other
                    operational, legal, or regulatory reasons. We will post the updated Privacy Policy on our website, and the
                    "Effective Date" will be revised accordingly.</p>
            </div>

            <div className="section">
                <p><strong>Contact Us</strong></p>
                <p>If you have any questions, concerns, or requests regarding this Privacy Policy or your personal information,
                    please contact us at:</p>
                <ul>
                    <li>Email: <a href="mailto:info@rapidleb.com">info@rapidleb.com</a></li>
                </ul>
            </div>

            <div className="section">
                <p><strong>Conclusion</strong></p>
                <p>By using our services, you acknowledge that you have read and understand this Privacy Policy and consent to
                    the collection and use of your personal information as described herein. Thank you for choosing Rapid.</p>
            </div>

            <h1 className="text-plant font-bold text-xl">Terms and Conditions for Rapid</h1>
            <div className="section">
                <p><strong>Effective Date:</strong> 21-September-2023</p>
                <p><strong>1. Acceptance of Terms</strong></p>
                <p>By using the services provided by Rapid you agree to comply with and be bound by these Terms and Conditions. If
                    you do not agree with these Terms and Conditions, please do not use our services.</p>
            </div>

            <div className="section">
                <p><strong>2. Description of Services</strong></p>
                <p>Rapid provides delivery and logistics services that enable users to receive, and track deliveries.</p>
            </div>

            <div className="section">
                <p><strong>3. User Eligibility</strong></p>
                <p>You must be of legal age in your jurisdiction to use our services. By using our services, you represent and
                    warrant that you are of legal age and have the legal capacity to enter into this agreement.</p>
            </div>

            <div className="section">
                <p><strong>4. User Registration</strong></p>
                <p>To use certain features of our services, you may be required to register for an account. You agree to provide
                    accurate, current, and complete information during the registration process and to update such information
                    to keep it accurate, current, and complete.</p>
            </div>

            <div className="section">
                <p><strong>5. User Responsibilities</strong></p>
                <p>a. You are responsible for maintaining the confidentiality of your account login information.</p>
                <p>b. You agree not to use our services for any illegal or unauthorized purpose and to comply with all applicable
                    laws and regulations.</p>
                <p>c. You agree not to interfere with or disrupt our services or the servers and networks connected to our
                    services.</p>
            </div>

            <div className="section">
                <p><strong>6. Payment</strong></p>
                <p>a. Payment for our services is required at the time of placing an order. We accept various payment methods,
                    including credit cards and other forms of electronic payment.</p>
                <p>b. Pricing for our services is subject to change.</p>
            </div>

            <div className="section">
                <p><strong>7. Delivery</strong></p>
                <p>a. We will make reasonable efforts to ensure timely and accurate delivery of your items. However, delivery times
                    may vary, and we do not guarantee delivery by a specific date or time.</p>
                <p>b. You are responsible for providing accurate delivery instructions, including the recipient's address and
                    contact information.</p>
            </div>

            <div className="section">
                <p><strong>8. Cancellation and Refunds</strong></p>
                <p>a. Orders may be canceled within a specified timeframe before delivery. Refund policies may vary, and you should
                    refer to our refund policy for details.</p>
            </div>

            <div className="section">
                <p><strong>9. Intellectual Property</strong></p>
                <p>All content and materials on our website and mobile application are owned or licensed by Rapid and are
                    protected by copyright and other intellectual property laws. You may not use our content without our express
                    written permission.</p>
            </div>

            <div className="section">
                <p><strong>10. Privacy</strong></p>
                <p>Our Privacy Policy outlines how we collect, use, and safeguard your personal information. By using our
                    services, you consent to the practices described in our Privacy Policy.</p>
            </div>

            <div className="section">
                <p><strong>11. Termination</strong></p>
                <p>We reserve the right to terminate or suspend your account and access to our services at our discretion, without
                    notice, for any violation of these Terms and Conditions or for any other reason.</p>
            </div>

            <div className="section">
                <p><strong>12. Limitation of Liability</strong></p>
                <p>To the extent permitted by law, Rapid and its affiliates shall not be liable for any indirect, incidental,
                    special, consequential, or punitive damages, or any loss of profits or revenues.</p>
            </div>

            <div className="section">
                <p><strong>13. Governing Law</strong></p>
                <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of Lebanon, without
                    regard to its conflict of law principles.</p>
            </div>

            <div className="section">
                <p><strong>14. Contact Information</strong></p>
                <p>If you have any questions, concerns, or inquiries regarding these Terms and Conditions, please contact us at:</p>
                <ul>
                    <li>Email: <a className="text-plant" href="mailto:info@rapidleb.com">support@rapidleb.com</a></li>
                </ul>
            </div>

            <div className="section">
                <p><strong>15. Changes to Terms and Conditions</strong></p>
                <p>We may update these Terms and Conditions from time to time. You will be notified of any changes, and the
                    "Effective Date" will be revised accordingly.</p>
            </div>
        </div>
    )
}