import { useRef, useState } from "react"
import { useOutsideFinder } from "../hooks/useOutsideFinder"
import useData from "../hooks/useData"
import { SearchIco } from "../assets/Icons"


export const CurrencySelector = ({ className, id, label, type }) => {
    const [show, setShow] = useState(false)
    const { store, setStore } = useData()
    const [searchKey, setSearchKey] = useState('')
    const selectorRef = useRef()
    const currencyList = [
        {
            key: 'USD',
            text: 'USD'
        },
        {
            key: 'LBP',
            text: 'LBP'
        }
    ]

    const filtered = currencyList.filter(x => { return x.text.toLowerCase().includes(searchKey.toLowerCase()) })

    useOutsideFinder(() => setShow(false), selectorRef)

    const handleChange = (val) => {
        setStore({ ...store, currency: val })
        setShow(c => false)
    }

    return (
        <div
            className={"flex md:flex-row flex-col md:items-center p-2 bg-transparent text-white dark:text-color5 w-full cursor-pointer " + className}>
            <label
                className="p-1 cursor-pointer flex-1/2 min-w-[7rem] text-plant"
                htmlFor={id}>{label}</label>
            <div className="relative" ref={selectorRef}>
                <div onClick={() => setShow(!show)}>
                    <input
                        type={type ? type : 'text'}
                        id={id}
                        className={"cursor-pointer outline-none bg-primary placeholder:italic placeholder:text-sm border border-0.5 border-transparent focus:border-plant rounded p-2 flex-1 w-full " + className}
                        value={store.currency}
                        placeholder={`Enter ${id}`}
                        readOnly />
                </div>
                {
                    show
                        ? <div className="absolute w-full -bottom-13 right-0 bg-primary z-[20]">
                            <div className="relative px-0.5">
                                <input className="w-full bg-transparent outline-none border border-transparent focus:border-color5 p-1 rounded-sm placeholder:text-sm placeholder:italic" placeholder="Search a value" value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                                <SearchIco className={'absolute right-2 top-1/2 -translate-y-1/2 w-4 h-4'} />
                            </div>
                            {
                                filtered
                                    ? filtered.map(x => {
                                        return <div key={x.key} className="p-2 hover:bg-plant hover:text-primary" onClick={() => handleChange(x.text)}>{x.text}</div>
                                    })
                                    : <div className="flex w-full justify-center text-sm">No data to show</div>
                            }
                        </div>
                        : null
                }
            </div>
        </div>

    )
}

export const GroupSelector = ({ className, id, label, type, data, group, fun, value, onChange }) => {
    const [show, setShow] = useState(false)
    const [searchKey, setSearchKey] = useState('')
    const selectorRef = useRef()
    // console.log(value)
    const filtered = data.filter(x => { return x.name.toLowerCase().includes(searchKey.toLowerCase()) })

    const selected = fun === 'INI' ? data.filter(x => { return x.id === value })
        : group === 'CAT' ? data.filter(x => { return x.id === value }) : data.filter(x => { return x.id === value })

    useOutsideFinder(() => setShow(false), selectorRef)

    const handleChange = async (val) => {
        onChange(val)
        setShow(c => false)
    }

    return (
        <div
            className={"flex md:flex-row flex-col md:items-center p-2 bg-transparent text-white dark:text-color5 w-full cursor-pointer " + className}>
            <label
                className="p-1 cursor-pointer flex-1/2 min-w-[7rem] text-plant"
                htmlFor={id}>{label}</label>
            <div className="relative h-full" ref={selectorRef}>
                <div onClick={() => setShow(!show)}>
                    <input
                        type={type ? type : 'text'}
                        id={id}
                        className={"cursor-pointer outline-none bg-primary placeholder:italic placeholder:text-sm border border-0.5 border-gray-light focus:border-plant rounded p-2 flex-1 w-full " + className}
                        value={selected.length > 0 ? selected[0].name : ''}
                        onChange={onChange}
                        placeholder={`Enter ${id}`}
                        readOnly />
                </div>
                {
                    show
                        ? <div className="fixed w-full h-full z-20">
                            <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none">
                                <div className="relative h-full overflow-auto h-full max-h-[50rem] flex-grow w-auto mx-auto max-w-md rounded shadow drop-shadow-xl z-[50] bg-secondary border border-secondary min-h-[20rem] p-3">
                                    <label className="font-semibold text-xl p-5 italic">Choose a {label}</label>
                                    <div className="relative px-0.5 py-3">
                                        <input className="w-full bg-transparent outline-none border border-color5 focus:border-color5 p-1 rounded-sm placeholder:text-sm placeholder:italic" placeholder="Search a value" value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                                        <SearchIco className={'absolute right-2 top-1/2 -translate-y-1/2 w-4 h-4'} />
                                    </div>
                                    {
                                        filtered
                                            ? filtered.map(x => {
                                                return <div key={x.id} className="p-4 hover:bg-plant hover:text-primary text-sm" onClick={() => handleChange(x.id)}>{x.name ? x.name : 'Undefined'}</div>
                                            })
                                            : <div className="flex w-full justify-center text-sm">No data to show</div>
                                    }
                                </div>
                                <div className="fixed inset-0 bg-primary z-[10]" onClick={() => setShow(false)}></div>
                            </div>
                        </div>
                        : null
                }
            </div>
        </div>
    )
}

export const AddonsTypeSelector = ({ className, id, type, value }) => {
    const [show, setShow] = useState(false)
    const { addons, setAddons } = useData()
    const [searchKey, setSearchKey] = useState('')
    const selectorRef = useRef()
    const list = [
        {
            key: 'Add',
            text: 'Add Addons'
        },
        {
            key: 'Remove',
            text: 'Remove Addons'
        }
    ]

    const filtered = list.filter(x => { return x.text.toLowerCase().includes(searchKey.toLowerCase()) })

    useOutsideFinder(() => setShow(false), selectorRef)


    const handleChange = (index, e) => {
        const rowsInput = [...addons];
        rowsInput[index]['type'] = e;
        console.log(addons)
        setAddons(rowsInput)
        setShow(c => false)
    }

    return (
        <div className={"flex md:flex-row flex-col md:items-center p-2 bg-transparent text-white dark:text-color5 w-full cursor-pointer " + className}>
            <div className="relative" ref={selectorRef}>
                <div onClick={() => setShow(!show)}>
                    <input
                        type={type ? type : 'text'}
                        id={id}
                        className={"cursor-pointer outline-none bg-transparent placeholder:italic placeholder:text-sm border border-0.5 border-transparent focus:border-plant rounded p-2 flex-1 w-full " + className}
                        value={value}
                        placeholder={`Choose a type`}
                        readOnly />
                </div>
                {
                    show
                        ? <div className="absolute w-full -bottom-13 right-0 bg-primary z-[50] border">
                            <div className="relative px-0.5">
                                <input className="w-full bg-transparent outline-none border border-transparent focus:border-color5 p-1 rounded-sm placeholder:text-sm placeholder:italic" placeholder="Search a value" value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                                <SearchIco className={'absolute right-2 top-1/2 -translate-y-1/2 w-4 h-4'} />
                            </div>
                            {
                                filtered
                                    ? filtered.map(x => {
                                        return <div key={x.key} className="p-2 hover:bg-plant hover:text-primary" onClick={() => handleChange(id, x.key)}>{x.text}</div>
                                    })
                                    : <div className="flex w-full justify-center text-sm">No data to show</div>
                            }
                        </div>
                        : null
                }
            </div>
        </div>

    )
}