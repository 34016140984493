import { useParams } from "react-router-dom";
import { Container, ContainerHeader } from "../conponents/Containers";
import { Margins } from "../conponents/Margins";
import useData from "../hooks/useData";
import { useEffect, useState } from "react";
import { formatDateTime } from "../hooks/ConvertTime";

export default function Payments() {
    const { username } = useParams()
    const { store, orderAction, hideSplash, displayToast, setShowLoading } = useData()
    const [paymentList, setPaymentList] = useState([])
    const [showPay, setShowPay] = useState(false)
    const [amount, setAmount] = useState('')

    const thStyle = 'text-left font-bold p-2 border-b border-color5 text-white-gray'
    const tdStyle = 'p-2 border-b border-gray-light'

    const handleSubmit = async () => {
        setShowLoading(true)
        orderAction('RECEIVE_PAYMENT', { amount })
            .then(response => {
                const { status, data, err } = response
                if (status) {
                    displayToast(data)
                    setAmount('')
                    setShowPay(false)
                } else {
                    displayToast(err)
                }
                setTimeout(() => setShowLoading(false), 1500)
            })
    }

    useEffect(() => {
        setShowLoading(true)
        orderAction('GET_PAYMENTS')
            .then(async (response) => {
                const { status, data, err } = response
                if (status) {
                    await setPaymentList(data)
                } else {
                    hideSplash(1200)
                }
                setTimeout(() => setShowLoading(false), 1200)
            })
            .catch(err => console.log(err))
        // eslint-disable-next-line
    }, [])

    return (
        <Margins active={'report'} >
            <Container >
                <ContainerHeader page={'Report'} username={username} showBack={true} />
                <div className="flex flex-col md:flex-row my-3 items-center">
                    <button className="bg-plant text-primary p-2 hover:opacity-60 w-fit h-fit" onClick={() => setShowPay(!showPay)}>Receive from Rapid</button>
                    {
                        showPay && <div className="flex flex-row md:flex-row md:max-w-[20%] w-full p-2 items-center">
                            <input className="outline-none w-full bg-secondary p-2 placeholder:text-sm placeholder:italic" placeholder="Enter an amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                            <button className="bg-color7 text-primary p-2 hover:opacity-60 h-fit" onClick={handleSubmit}>Receive</button>
                        </div>
                    }
                </div>
                <div className="w-full overflow-auto">
                    <table className="w-full overflow-auto">
                        <thead>
                            <tr>
                                <th className={thStyle}>#</th>
                                <th className={thStyle}>Rate</th>
                                <th className={thStyle}>Amount Paid</th>
                                <th className={thStyle}>Created time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                paymentList && paymentList?.length
                                    ? paymentList?.map((x, index) => {
                                        return <tr key={index}>
                                            <td className={tdStyle}>{x.id}</td>
                                            <td className={tdStyle}>{x.store_rate}</td>
                                            <td className={tdStyle}>{x.amount_paid} {store.currency}</td>
                                            {/* <td className={tdStyle}>{store.currency} {x.service_charge}</td> */}
                                            <td className={tdStyle}>{formatDateTime(x.created_at)}</td>
                                        </tr>
                                    })
                                    : null
                            }
                        </tbody>
                    </table>
                    {
                        paymentList && paymentList?.length === 0
                            ? <div className="italic text-sm text-white-gray w-full flex justify-center items-center p-1">No data to show</div>
                            : null
                    }
                </div>
            </Container>
        </Margins>
    )
}