import { useEffect, useState } from "react";
import useData from "../hooks/useData";
import { useLocation } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import logo from '../assets/img/logo.png'
import { Margins } from "../conponents/Margins";
import { CentralContainer, ContainerHeader } from "../conponents/Containers";
import { ToDecimal } from "../hooks/ConvertNumber";

const Detail = ({ label, value, suffix = '', className }) => {
    return (
        <div className="flex bg-primary text-color5 font-bold">
            <div className={`flex flex-row w-full justify-start items-center shadow p-4 ${className}`}>
                <span className="float-left w-full italic text-[16px]">{label}</span>
                <span className="float-right text-right w-full">{value}</span>
                <span className="float-right text-right w-fit px-1">{suffix}</span>
            </div>
            <div className="flex-1"></div>
        </div>
    )
}

export default function OrderDetails() {
    const { state } = useLocation()
    const { store, hideSplash, orderAction, displayToast } = useData()
    const [hideImage, setHideImage] = useState(true)

    const [order, setOrder] = useState(state?.order ? state.order : {})
    const [detail, setDetail] = useState(state?.data ? state.data : [])

    const total = () => detail?.reduce((prev, current) => {
        return ToDecimal(prev + (current.price * current.quantity))
    }, 0);


    const handleConfirmOrder = (e) => {
        e.preventDefault()
        e.stopPropagation()
        orderAction('CONFIRM_ORDER', order.id)
            .then(response => {
                console.log(response)
                const { status, data, err } = response
                if (status) {
                    displayToast(data)
                    setOrder({ ...order, is_confirmed: 1 })
                    // window.location.reload()
                }
                else
                    displayToast(err)
            })
            .catch(err => displayToast(err))
    }

    useEffect(() => {
        hideSplash()
        orderAction('GET_ORDER', order)
            .then(response => {
                const { status, data, err } = response
                if (status)
                    setOrder(data[0])
                else
                    displayToast(err)
            })
            .catch(err => { })
        orderAction('GET_ORDER_ITEMS', order)
            .then(response => {
                const { status, data, err } = response
                if (status)
                    setDetail(data)
                else
                    displayToast(err)
            })
            .catch(err => { })
        // eslint-disable-next-line
    }, [])

    return (
        <Margins active={'order'}>
            <CentralContainer>
                <ContainerHeader page={`Order #${order.id}`} username={`${(order.created_at ? formatDistanceToNow(new Date(order.created_at)) : '')} ago`} showBack={true} />
                <div className="flex flex-col bg-primary text-white w-full h-full overflow-auto">
                    <Detail label={'Order #'} value={order.id} className={'bg-primary text-plant text-[24px] font-bold'} />
                    <Detail label={'Created time'} value={order.created_at ? formatDistanceToNow(new Date(order.created_at)) : ''} suffix={'ago'} />


                    <div className="flex flex-col w-full h-full overflow-auto relative">
                        <div className="flex items-center h-fit p-2 shadow text-color5 bg-primary border-primary border-2 border-t-secondary border-b-secondary sticky top-0 z-[10]">
                            <div className="relative text-color5 min-w-12 w-12 min-h-12 h-12 rounded-full overflow-hidden object-fill flex items-center bg-transparent" >
                            </div>
                            <span className="flex-grow px-2 truncate w-full">Items</span>
                            <span className="px-2 w-12">Qty</span>
                            <span className="px-2 min-w-[5rem] w-[20%] text-right">Price</span>
                        </div>
                        {
                            detail
                                ? detail.map((x, index) => {
                                    return <div key={index} className="flex items-center h-fit p-4 xs:p-2 shadow">
                                        <div className="relative w-[6rem] h-[4rem] max-h-min rounded-full  hidden xs:block items-center justify-center bg-secondary" >
                                            <img src={logo} alt="logo" className="absolute -z-1 object-cover p-4" />
                                            <img onLoad={() => { setHideImage(false) }} onError={() => { setHideImage(true) }} src={x.image} alt="Item" className={`w-full h-full rounded-full ${hideImage ? 'hidden' : ''}`} />
                                        </div>
                                        <span className="flex-grow w-full flex flex-col px-3 italic">
                                            <span>{x.product_name}</span>
                                            <span className="text-sm mt-1 text-color5 italic">{x.addons}</span>
                                            {x.instruction && <span className="text-sm mt-1 text-white-gray italic"><span className="text-red">***</span> {x.instruction}</span>}
                                        </span>
                                        <span className="px-2 w-12">{x.quantity}</span>
                                        <span className="px-2 min-w-[5rem] w-[20%] text-right truncate" title={`${store.currency} ${x.price}`}>{store.currency} {x.price * x.quantity}</span>
                                    </div>
                                })
                                : <span>error</span>
                        }
                    </div>
                    <div className="flex flex-col items-center justify-between">
                        <span className="w-full text-right italic text-xl p-2 py-4 bg-primary text-plant text-[24px]">Total: {store.currency} {total()}</span>
                    </div>
                </div>
                <div className="italic py-1 text-green flex justify-end">{order.is_confirmed === 1 ? 'Confirmed' : <button className="bg-green text-primary p-2 rounded font-bold" onClick={handleConfirmOrder}>Confirm</button>}</div>
            </CentralContainer>
        </Margins>
    )
}