import { useNavigate, useParams } from "react-router-dom"
import { Margins } from "../conponents/Margins"
import { useEffect, useState } from "react"
import useData from "../hooks/useData"
import { Container } from "../conponents/Containers"
import { formatDateTime } from "../hooks/ConvertTime"
import profitIcon from '../assets/img/profit.png'
import { ToDecimal } from "../hooks/ConvertNumber"

const CardTop = ({ title, value, color, valueSize, titleColor }) => {

    return (
        <div className={`flex flex-col rounded-[20%] min-w-[16rem] h-[14rem] overflow-hidden border border-gray-light ${color}`}>
            <span className={`flex items-center h-[6rem] opacity-60 bg-primary ${titleColor} justify-center text-2xl font-bold px-2 `}>{title}</span>
            <span className={`flex-grow flex items-center justify-center ${valueSize}`}>{typeof (value) === "number" ? ToDecimal(value) : value}</span>
        </div>
    )
}


export default function Home() {
    const { username } = useParams()
    const navigate = useNavigate()
    const { orderAction, displayToast, setShowLoading } = useData()
    const [orderList, setOrderList] = useState([])

    const uniqueOrders = orderList.reduce((acc, currentOrder) => {
        // Find the index of the existing order with the same order_id
        const existingIndex = acc.findIndex(order => order.order_id === currentOrder.order_id);

        if (existingIndex === -1) {
            // If the order_id doesn't exist in the accumulator, add the current order
            acc.push(currentOrder);
        } else {
            // If the order_id already exists, compare order_id and keep the one with the maximum value
            if (currentOrder.order_id > acc[existingIndex].order_id) {
                acc[existingIndex] = currentOrder;
            }
        }

        return acc;
    }, []).sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).slice(0, 10)
    const todaysOrders = uniqueOrders?.filter(x => formatDateTime(new Date(x.created_at), 'yyyy-MM-dd') >= formatDateTime(new Date(), 'yyyy-MM-dd')).length
    const lastOrder = orderList?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    const lastOrderList = orderList?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).slice(0, 10)
    const totalSales = orderList?.filter(x => formatDateTime(new Date(x.created_at), 'yyyy-MM-dd') >= formatDateTime(new Date(), 'yyyy-MM-dd')).reduce((total, order) => {
        console.log(order.id, order.price, '*', order.quantity, '=', (order.price * order.quantity))
        return total + (order.price * order.quantity);
    }, 0);

    const calculateAverage = () => {
        if (orderList.length === 0) {
            return 0;
        }

        const totalAmount = orderList.reduce((acc, order) => acc + order, 0)
        // console.log(totalAmount)
        return totalAmount / orderList.length;
    };

    useEffect(() => {
        setShowLoading(true)
        if (!username && username !== 'undefined')
            navigate('/Login')
        orderAction('GET_ITEMS')
            .then(async (response) => {
                const { status, data, err } = response
                if (status) {
                    await setOrderList(data)
                    console.log(orderList)
                } else {
                    displayToast(err)
                }
                setTimeout(() => setShowLoading(false), 1200)
            })
            .catch(err => displayToast(err))
        // eslint-disable-next-line
    }, [])

    return (
        <Margins username={username}>
            <Container>
                <div className="flex flex-wrap justify-center items-center gap-6">
                    <CardTop color={'bg-secondary text-plant'} titleColor={'text-plant'} title={'Daily Sales'} value={totalSales} valueSize={'text-[3rem]'} />
                    <CardTop color={'bg-secondary text-plant'} titleColor={'text-plant'} title={'Daily Orders'} value={todaysOrders} valueSize={'text-[3rem]'} />
                    <CardTop color={'bg-secondary text-plant'} titleColor={'text-plant'} title={'Avarage Orders'} value={calculateAverage()} valueSize={'text-[3rem]'} />
                    <CardTop color={'bg-secondary text-plant'} titleColor={'text-plant'} title={'Last Order'} value={formatDateTime(lastOrder[0]?.created_at, 'MMM dd, HH:mm')} valueSize={'text-[2rem]'} />
                    {/* <Card color={'bg-color4 text-primary'} title={'Last Delivery'} value={formatDateTime(lastOrder[0]?.created_at)} /> */}
                </div>
                <div className="w-full flex flex-col md:flex-row mt-10 justify-center gap-2">
                    <div className="flex-1 md:overflow-auto">
                        <h4 className="italic font-bold rounded-t-xl text-primary p-4 bg-color5">Last 10 orders</h4>
                        {
                            uniqueOrders.length > 0
                                ? uniqueOrders?.map((x, index) => {
                                    return <div key={index} className="flex flex-col w-full p-4 border border-gray-light my-1 bg-black">
                                        <span className="text-white">Created at {formatDateTime(x.created_at)}</span>
                                        <span>Order #{x.id}</span>
                                    </div>
                                })
                                : <div className="text-center italic p-2">No data to show</div>
                        }
                    </div>
                    <div className="flex-1 md:overflow-auto">
                        <h4 className="italic font-bold rounded-t-xl text-primary p-4 bg-color5">Most Popular</h4>
                        {
                            uniqueOrders.length > 0
                                ? uniqueOrders?.map((x, index) => {
                                    return <div key={index} className="flex flex-row items-center w-full p-4 border h- border-gray-light my-1 bg-black">
                                        <div className="flex flex-row rounded-full border w-10 p-0">
                                            <img src={profitIcon} className="w-full" alt="" />
                                        </div>
                                        <div className="flex flex-col pl-3">
                                            <span>{x.name} - </span>
                                        </div>
                                        <div className="flex flex-col pl-3">
                                            <span>{x.quantity} pcs</span>
                                        </div>
                                        <div className="flex flex-col pl-3">
                                            <span>Order #{x.order_id}</span>
                                        </div>

                                    </div>
                                })
                                : <div className="text-center italic p-2">No data to show</div>
                        }
                    </div>
                </div>
            </Container>
        </Margins>
    )
}