// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getStorage } from "firebase/storage"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCL4bcFKMc-_aW3EltozcVm4UYMSajyNiI",
    authDomain: "rapid-247f3.firebaseapp.com",
    projectId: "rapid-247f3",
    storageBucket: "rapid-247f3.appspot.com",
    messagingSenderId: "863952080426",
    appId: "1:863952080426:web:4991d318153f837301bd53",
    measurementId: "G-X4J5X1X517"
}

// Initialize Firebase
initializeApp(firebaseConfig)

// Initialize Cloud Storage and get a reference to the service
export const storage = getStorage()

export const giveCurrentDateTime = () => {
    const today = new Date()
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds() + '.' + today.getMilliseconds()
    const dateTime = date + ' ' + time
    return dateTime
}