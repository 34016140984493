import { useNavigate } from "react-router-dom";


export default function BackButton() {
    const navigate = useNavigate()

    return (
        <div
            className="px-2 cursor-pointer" onClick={() => navigate(-1)}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
            </svg>
        </div>
    )
}