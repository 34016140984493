import '../assets/css/loaders.css'
import useData from '../hooks/useData'
import logo from '../assets/img/logo.png'


export const TextLoader = () => {
    return (
        <span className="loader"></span>
    )
}

export const ContainerLoader = () => {
    const { showLoading } = useData()
    return (
        showLoading
            ? <div className="fixed w-full min-w-screen h-full min-h-full bg-primary flex justify-center items-center z-[100]">
                <img src={logo} className='animate-logo max-w-[12vw]' alt='Rapidleb Co.' />
            </div>
            : null
    )
}