import { useState } from "react";
import { SearchInput } from "./Input";

const Addon = ({ id, data, selected, setSelected }) => {

    const handleChange = () => {
        const exist = selected.filter(x => { return x.id === data.id })
        if (exist?.length > 0)
            return
        setSelected([...selected, { ...data, options: [] }])
    }

    return (
        <div className="w-full flex bg-secondary shadow-sm shadow-primary py-1 items-center p-1 gap-1" style={{ cursor: data.id ? "pointer" : "default" }} onClick={handleChange}>
            <span className="p-2 text-color5">{data.name ? data.name : null}</span>
        </div>
    );
};

export default function Addons({ addonsList, selected, setSelected, setShow }) {
    const [searchKey, setSearchKey] = useState('')

    const filtered = addonsList?.filter(x => { return x.name.toLowerCase().includes(searchKey.toLowerCase()) })
    return (
        <div className="fixed w-full h-full z-20">
            <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none">
                <div className="relative flex-grow w-auto mt-20 lg:mx-auto mx-0 max-w-md rounded shadow drop-shadow-xl z-50 bg-secondary">
                    <SearchInput className="text-color5" value={searchKey} id={'addons'} onChange={(e) => setSearchKey(e.target.value)} onRemove={() => setSearchKey('')} />
                    {
                        filtered
                            ? filtered.map((x, index) => {
                                return (
                                    <Addon key={index} id={index} data={x} selected={selected} setSelected={setSelected} />
                                );
                            })
                            : null
                    }
                </div>
                <div className="fixed inset-0 z-40 bg-primary opacity-90" onClick={() => setShow(false)}></div>
            </div>
        </div>
    );
}