import AWS from 'aws-sdk'


export const S3_BUCKET = process.env.REACT_APP_S3_BUCKET
const REGION = process.env.REACT_APP_REGION

// S3 Credentials
AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
})

export const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})