import { useParams } from "react-router-dom";
import { CentralContainer, ContainerFooter, ContainerHeader } from "../conponents/Containers";
import { Margins } from "../conponents/Margins";
import { useEffect, useLayoutEffect, useState } from "react";
import useData from "../hooks/useData";
import { ToDecimal } from "../hooks/ConvertNumber";
import { CancelOutlineIco, SubmitOutlineIco } from "../assets/Icons";

const SinglePriceList = ({ id, data, name, discount, hideImage = false, onDiscount, onChangePrice, onChangeDiscount, priceType }) => {
    const { store } = useData()
    const [hideNotFOund, setHideNotFound] = useState()

    return (
        <div className="flex xs:flex-row flex-col items-center p-3 shadow bg-black my-1 rounded-md">
            {
                !hideImage
                    ? <>
                        <div className="w-[5rem] h-[5rem] min-w-[5rem] min-h-[5rem] error-img rounded-full bg-secondary overflow-hidden">
                            <img src={data.image} className={`w-full h-full bg-secondary error-img overflow-hidden ${hideNotFOund && 'hidden'} `} onError={() => setHideNotFound(true)} alt="" />
                        </div>
                        <span title={data.name} className="flex flex-grow p-3 font-bold text-ellipsis overflow-hidden whitespace-nowrap min-w-[4rem] text-[min(12vw, 2.5rem)]">{data.name}
                            {
                                data.updated
                                    ? data.updated === 'Ok' ? <SubmitOutlineIco /> : <CancelOutlineIco />
                                    : null
                            }
                        </span>
                        {priceType === 'offer' && <div className="relative text-primary mx-1">
                            <input type="number" className="outline-none border border-transparent pr-6 bg-primary text-white-gray font-bold p-2 rounded max-w-[4rem] placeholder:text-sm placeholder:italic placeholder:text-secondary text-right" placeholder="0" value={data.newPer} onChange={(e) => onChangeDiscount(e, id)} />
                            <span className="absolute top-1/2 -translate-y-1/2 right-1 font-bold text-white-gray">%</span>
                        </div>}
                        <div className="relative text-primary py-0.5">
                            <input type="number" className="outline-none border border-transparent pr-10 bg-primary text-white-gray font-bold p-2 rounded max-w-[4rem] placeholder:text-sm placeholder:italic placeholder:text-secondary text-right min-w-[8rem]" placeholder="0" value={ToDecimal(data.newPrice)} onChange={(e) => onChangePrice(e, id)} />
                            <span className="absolute top-1/2 -translate-y-1/2 right-1 font-bold text-white-gray">{store.currency}</span>
                        </div>
                    </>
                    : <>
                        <span title={'Make a discount on all you product'} className="flex-grow p-3 font-bold text-sm text-right italic text-ellipsis overflow-hidden whitespace-nowrap min-w-[4rem] text-[min(1vw, 0.5rem)]">{name}</span>
                        {<div className="relative text-primary py-0.5">
                            <input type="number" className="outline-none border border-transparent pr-6 bg-primary text-white-gray font-bold p-2 rounded max-w-[4rem] placeholder:text-sm placeholder:italic placeholder:text-secondary text-right" placeholder="0" value={discount} onChange={onDiscount} />
                            <span className="absolute top-1/2 -translate-y-1/2 right-1 font-bold text-white-gray">%</span>
                        </div>}
                    </>
            }
        </div>
    )
}

export default function PriceList() {
    const { username } = useParams()
    const [discount, setdiscount] = useState(0)
    const { setShowLoading, productAction, hideSplash, displayToast } = useData()
    const [newList, setNewList] = useState([])
    const [priceType, setPriceType] = useState('change')

    const handleChangeDiscount = async (e) => {
        const value = e.target.value
        setdiscount(value)
        if (value) {
            productAction('GET_PRICES')
                .then(response => {
                    const { status, data, err } = response
                    if (status) {
                        const result = data.map(x => {
                            const current = x.price
                            const newPrice = current - (current * value / 100)
                            return { ...x, newPrice: newPrice, newPer: value }
                        })
                        setNewList(result)
                    } else { displayToast(err) }
                })
        }
        return
    }

    const handleChangePrices = (e, id) => {
        const newPrice = e.target.value
        if (newPrice) {
            const result = newList.map(x => {
                const roundedNumber = Math.round(newPrice * 100) / 100;
                if (x.id === id)
                    return { ...x, newPrice: roundedNumber, newPer: (100 - (newPrice * 100 / x.price)) }
                return { ...x }
            })
            setNewList(result)
        }
        return
    }

    const handleChangeSingleDiscount = (e, id) => {
        const newPer = e.target.value
        if (newPer) {
            const result = newList.map(x => {
                if (x.id === id)
                    return { ...x, newPrice: (x.price - (x.price * newPer / 100)), newPer: newPer }
                return { ...x }
            })
            setNewList(result)
        }
        return
    }

    useLayoutEffect(() => {
        setShowLoading(true)
        productAction('GET_PRICES')
            .then(async response => {
                const { status, data, err } = response
                if (status) {
                    const finalList = await data?.map(x => {
                        return { ...x, newPrice: x.price, newPer: x.discount }
                    })
                    setNewList(finalList)
                } else { displayToast(err) }
            })
        setTimeout(() => setShowLoading(false), 1200)
        // eslint-disable-next-line
    }, [])

    const handleSubmit = () => {
        setShowLoading(true)
        if (priceType === 'change')
            productAction('UPDATE_PRICE_LIST', newList)
                .then(async respone => {
                    const { data, err, status } = respone
                    if (status) {
                        const { message, returnedList } = data
                        displayToast(message)
                        setNewList(returnedList)
                        setTimeout(() => setShowLoading(false), 1200)
                    } else {
                        displayToast(err)
                        const finalList = await data?.map(x => {
                            return { ...x, updated: 'error' }
                        })
                        setNewList(finalList)
                        setTimeout(() => setShowLoading(false), 1200)
                    }
                })
                .catch(async err => {
                    displayToast(err)
                    const finalList = await newList?.map(x => {
                        return { ...x, updated: 'error' }
                    })
                    setNewList(finalList)
                    setTimeout(() => setShowLoading(false), 1200)
                })
        else
            productAction('UPDATE_PRICE_OFFER', newList)
                .then(async respone => {
                    const { data, err, status } = respone
                    if (status) {
                        const { message, returnedList } = data
                        displayToast(message)
                        setNewList(returnedList)
                        setTimeout(() => setShowLoading(false), 1200)
                    } else {
                        displayToast(err)
                        const finalList = await data?.map(x => {
                            return { ...x, updated: 'error' }
                        })
                        setNewList(finalList)
                        setTimeout(() => setShowLoading(false), 1200)
                    }
                })
                .catch(async err => {
                    displayToast(err)
                    const finalList = await newList?.map(x => {
                        return { ...x, updated: 'error' }
                    })
                    setNewList(finalList)
                    setTimeout(() => setShowLoading(false), 1200)
                })
    }

    useEffect(() => {
        hideSplash()
        // eslint-disable-next-line
    }, [])

    return (
        <Margins username={username} active='pricelist'>
            <CentralContainer>
                <ContainerHeader username={username} page={'Price List'} showBack={true} />
                <div className="flex">
                    <div className="flex items-center w-full cursor-pointer">
                        <input className="cursor-pointer" type="radio" name="type" id="changePrice" value={'change'} onChange={(e) => setPriceType(e.target.value)} checked={priceType === 'change' ? 'checked' : ''} />
                        <label className="px-1 cursor-pointer" htmlFor="changePrice">Change Price</label>
                    </div>
                    <div className="flex items-center w-full cursor-pointer">
                        <input className="cursor-pointer" type="radio" name="type" id="offer" value={'offer'} onChange={(e) => setPriceType(e.target.value)} checked={priceType === 'offer' ? 'checked' : ''} />
                        <label className="px-1 cursor-pointer" htmlFor="offer">Offer</label>
                    </div>
                    <ContainerFooter text={'Save'} onClick={handleSubmit} />
                </div>
                {priceType === 'offer' && <SinglePriceList key={-1} discount={discount} hideImage={true} name={'Discount on all'} onDiscount={handleChangeDiscount} />}
                <div className="h-full bg-primary overflow-x-auto">
                    {
                        newList && newList.length > 0
                            ? newList.map((x, index) => {
                                return <SinglePriceList key={index} id={x.id} data={x} onChangePrice={handleChangePrices} priceType={priceType} onChangeDiscount={handleChangeSingleDiscount} />
                            })
                            : <span className="text-sm italic">No data to show</span>
                    }
                </div>
            </CentralContainer>
        </Margins>
    )
}