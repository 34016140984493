import { createContext, useEffect, useLayoutEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import axios from "axios";
import { BASEURL, checkTokenExpiration } from "../hooks/useAxios";
import { toast } from 'react-hot-toast'
import CustomToast from "../conponents/CustomToast";
import { format } from 'date-fns'
import { Spalsh } from "../conponents/Splash";
import { delay } from "lodash";

const DataContext = createContext({})

export const DataProvider = ({ children }) => {
    const [showSplash, setShowSplash] = useState(true)
    const [showLoading, setShowLoading] = useState(false)
    const [refresh, setRefresh] = useState(true)
    const [showMenu, setShowMenu] = useState(true)
    const [accessToken, setAccessToken] = useState('')
    const [decimalDigit, setDecimalDigit] = useState(2)
    const [percent, setPercent] = useState(0)

    const [store, setStore] = useState({ id: '', cover: null, image: null, name: '', description: '', phone: '', email: '', facebook: '', instagram: '', tiktok: '', exchange_rate: 0, currency: '', address: '', location: '' })

    const [workingHours, setWorkingHours] = useState([
        {
            id: 1,
            day: 'Mon',
            enable: false,
            start: '',
            end: ''
        }, {
            id: 2,
            day: 'Tue',
            enable: false,
            start: '',
            end: ''
        }, {
            id: 3,
            day: 'Wed',
            enable: false,
            start: '',
            end: ''
        }, {
            id: 4,
            day: 'Thu',
            enable: false,
            start: '',
            end: ''
        }, {
            id: 5,
            day: 'Fri',
            enable: false,
            start: '',
            end: ''
        }, {
            id: 6,
            day: 'Sat',
            enable: false,
            start: '',
            end: ''
        }, {
            id: 7,
            day: 'Sun',
            enable: false,
            start: '',
            end: ''
        },
    ])

    const [product, setProduct] = useState({ id: '', image: '', name: '', description: '', category_id: '', subcategory_id: '', price: '', discount: '', active: 1, createddate: format(new Date(), 'yyyy-MM-dd HH:mm:ss') })
    const [productList, setProductList] = useState([])
    const [addons, setAddons] = useState([])

    const [category, setCategory] = useState({ id: '', name: '', image_url: '' })
    const [categoryList, setCategoryList] = useState([])

    const [subCategory, setSubCategory] = useState({ id: '', name: '', image_url: '', category_id: -1 })
    const [subCategoryList, setSubCategoryList] = useState([])

    const [tagList, setTagList] = useState([])

    const displayToast = (message) => {
        toast.custom((t) => (
            <CustomToast t={t} message={message} />
        ))
    }

    const storeAction = async (action, payload) => {
        try {
            switch (action) {
                case 'UPDATE': {
                    const config = await checkTokenExpiration()
                    axios.post(`${BASEURL}/store/update `, { store: store, workingHours: workingHours, tags: tagList }, config)
                        .then(response => {
                            const { status, data, err } = response.data
                            if (!status)
                                displayToast(err)
                            else {
                                displayToast(data)
                                setRefresh(true)
                            }
                        })
                        .catch(err => {
                            displayToast(err)
                        })
                    return true
                }

                case 'GET': {
                    const config = await checkTokenExpiration()
                    axios.post(`${BASEURL}/store`, null, config)
                        .then(response => {
                            const { data, status } = response.data
                            if (status)
                                setStore(c => data)
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    return true
                }

                case 'UPDATE_PROFILE': {
                    const config = await checkTokenExpiration()
                    axios.post(`${BASEURL}/store/update_profile`, { image_url: payload }, config)
                        .then(response => {
                            const { data, status, err } = response.data
                            if (!status && err)
                                displayToast(err)
                            else {
                                setStore({ ...store, image: payload })
                                displayToast(data)
                                setRefresh(true)
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    return true
                }

                case 'UPDATE_COVER': {
                    const config = await checkTokenExpiration()
                    axios.post(`${BASEURL}/store/update_cover`, { image_url: payload }, config)
                        .then(response => {
                            const { data, status, err } = response.data
                            if (!status && err)
                                displayToast(err)
                            else {
                                setStore({ ...store, image: payload })
                                displayToast(data)
                                setRefresh(true)
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    return true
                }

                default: return
            }
        } catch (err) {
            console.log(err)
            return
        }
    }

    const groupAction = async (payload, data) => {
        switch (payload) {
            case 'ADD_CAT': {
                const config = await checkTokenExpiration()
                axios.post(`${BASEURL}/group/addcat`, { category }, config)
                    .then(response => {
                        const { status, err } = response.data
                        if (status) {
                            displayToast('Catgeory added!')
                            setRefresh(true)
                        } else {
                            err && displayToast(err)
                        }
                    })
                    .catch(err => { console.log(err) })

                return true
            }
            case 'UPDATE_CAT': {
                const config = await checkTokenExpiration()
                axios.post(`${BASEURL}/group/updatecat`, { category: data }, config)
                    .then(response => {
                        const { status, err } = response.data
                        if (status) {
                            displayToast('Catgeory updated!')
                            setRefresh(true)
                        } else
                            displayToast(err)

                    })
                    .catch(err => { console.log(err) })
                return true
            }
            case 'GET_CAT': {
                const config = await checkTokenExpiration()
                axios.post(`${BASEURL}/group/getcat`, {}, config)
                    .then(response => {
                        const { status, data, err } = response.data
                        if (status)
                            setCategoryList(data)
                        else
                            displayToast(err)
                    })
                    .catch(err => { console.log(err) })
                return true
            }
            case 'CLEAR_CAT': {
                setCategory({ id: '', name: '', description: '' })
                return true
            }
            case 'ADD_SUBCAT': {
                const config = await checkTokenExpiration()
                axios.post(`${BASEURL}/group/addsubcat`, { subCategory }, config)
                    .then(response => {
                        const { status, data, err } = response.data
                        if (status) {
                            displayToast(data)
                            setRefresh(true)
                        } else {
                            displayToast(err)
                        }
                    })
                    .catch(err => { console.log(err) })
                return true
            }
            case 'UPDATE_SUBCAT': {
                const config = await checkTokenExpiration()
                axios.post(`${BASEURL}/group/updatesubcat`, { subcategory: data }, config)
                    .then(response => {
                        const { status, data, err } = response.data
                        if (status) {
                            displayToast(data)
                            setRefresh(true)
                        } else {
                            displayToast(err)
                        }
                    })
                    .catch(err => { console.log(err) })
                return true
            }
            case 'GET_SUBCAT': {
                const config = await checkTokenExpiration()
                axios.post(`${BASEURL}/group/getsubcat`, {}, config)
                    .then(response => {
                        const { status, data, err } = response.data
                        if (status) {
                            setSubCategoryList(data)
                        }
                    })
                    .catch(err => { })
                return true
            }
            case 'GET_SUBCAT_BYCAT': {
                const config = await checkTokenExpiration()
                axios.post(`${BASEURL}/group/getsubcatbycat`, { category: product.category }, config)
                    .then(response => {
                        const { status, data, err } = response.data
                        if (status) {
                            setSubCategoryList(data)
                        } else
                            displayToast(err)
                    })
                    .catch(err => { })
            }
            case 'GET_CAT_PRODUCT': {
                const config = await checkTokenExpiration()
                return axios.post(`${BASEURL}/group/getcat`, {}, config)
                    .then(response => {
                        const { status, data, err } = response.data
                        return { status, data, err }
                    })
                    .catch(err => { return { status: 400, data: null, err } })
            }
            case 'CLEAR_SUBCAT': {
                setSubCategory({ id: '', name: '', description: '', parent: -1 })
                return true
            }
            default: return false
        }
    }

    const productAction = async (action, data) => {
        switch (action) {
            case 'GET_PRICES': {
                const config = await checkTokenExpiration()
                return axios.post(`${BASEURL}/product`, {}, config)
                    .then(response => {
                        const { status, data, err } = response.data
                        return { status, data, err }
                    })
                    .catch(err => { return { status: 400, data: null, err } })
            }
            case 'GET': {
                const config = await checkTokenExpiration()
                return axios.post(`${BASEURL}/product`, {}, config)
                    .then(response => { return { status: response.data.status, data: response.data.data, err: response.data.err } })
                    .catch(err => { return { status: 400, data: null, err } })
            }
            case 'GET_BY_ID': {
                const config = await checkTokenExpiration()
                const result = axios.post(`${BASEURL}/product/id`, { id: data.updateId }, config)
                    .then(response => {
                        const { status, data, err, addons } = response.data
                        // console.log(data)
                        return {
                            status,
                            product: {
                                id: data.id,
                                image: data.image,
                                name: data.name,
                                description: data.description,
                                category: data.category_id,
                                subcategory: data.subcategory_id,
                                price: data.price,
                                discount: data.discount,
                                active: data.active,
                                createddate: data.createddate
                            },
                            addons,
                            err
                        }
                    })
                    .catch(err => { return { status: 400, data: null, err } })
                return result
            }
            case 'ADD': {
                console.log('first')
                const config = await checkTokenExpiration()
                return axios.post(`${BASEURL}/product/add`, { product: data.product, addons: data.addons }, config)
                    .then(response => { return { status: response.data.status, data: response.data.data, err: response.data.err } })
                    .catch(err => { return { status: 400, data: null, err } })
            }
            case 'UPDATE': {
                const config = await checkTokenExpiration()
                return axios.post(`${BASEURL}/product/update`, { product: data }, config)
                    .then(response => { return { status: response.data.status, data: response.data.data, err: response.data.err } })
                    .catch(err => { return { status: 400, data: null, err } })
            }
            case 'UPDATE_PRICE_LIST': {
                const config = await checkTokenExpiration()
                return axios.post(`${BASEURL}/product/pricelist`, { updateList: data }, config)
                    .then(response => { return { status: response.data.status, data: response.data.data, err: response.data.err } })
                    .catch(err => { return { status: 400, data: null, err } })
            }
            case 'UPDATE_PRICE_OFFER': {
                const config = await checkTokenExpiration()
                return axios.post(`${BASEURL}/product/pricelistoffer`, { updateList: data }, config)
                    .then(response => { return { status: response.data.status, data: response.data.data, err: response.data.err } })
                    .catch(err => { return { status: 400, data: null, err } })
            }
            case 'CLEAR': {
                return { id: '', image: '', name: '', description: '', category: '', subcategory: '', price: '', discount: '', active: 1, createddate: format(new Date(), 'yyyy-MM-dd HH:mm:ss') }
            }
            default: return false
        }
    }

    const orderAction = async (action, data) => {
        switch (action) {
            case 'GET': {
                const config = await checkTokenExpiration()
                return axios.post(`${BASEURL}/order/`, {}, config)
                    .then(response => { return { status: response.data.status, data: response.data.data, err: response.data.err } })
                    .catch(err => { return { status: 400, data: null, err } })
            }
            case 'GET_ORDER': {
                const config = await checkTokenExpiration()
                return axios.post(`${BASEURL}/order/get`, { id: data }, config)
                    .then(response => { return { status: response.data.status, data: response.data.data, err: response.data.err } })
                    .catch(err => { return { status: 400, data: null, err } })
            }
            case 'GET_ORDER_ITEMS': {
                const config = await checkTokenExpiration()
                return axios.post(`${BASEURL}/order/id`, { id: data }, config)
                    .then(response => { return { status: response.data.status, data: response.data.data, err: response.data.err } })
                    .catch(err => { return { status: 400, data: null, err } })
            }
            case 'GET_ORDER_ITEMS_STORE': {
                const config = await checkTokenExpiration()
                return axios.post(`${BASEURL}/order/store`, { id: data }, config)
                    .then(response => { return { status: response.data.status, data: response.data.data, err: response.data.err } })
                    .catch(err => { return { status: 400, data: null, err } })
            }
            case 'GET_ITEMS': {
                const config = await checkTokenExpiration()
                return axios.post(`${BASEURL}/order/items`, {}, config)
                    .then(response => {
                        // console.log(response.data)
                        return { status: response.data.status, data: response.data.data, err: response.data.err }
                    })
                    .catch(err => { return { status: 400, data: null, err } })
            }
            case 'GET_POPULAR': {
                const config = await checkTokenExpiration()
                return axios.post(`${BASEURL}/order/items_total`, {}, config)
                    .then(response => { return { status: response.data.status, data: response.data.data, err: response.data.err } })
                    .catch(err => { return { status: 400, data: null, err } })
            }
            case 'CONFIRM_ORDER': {
                const config = await checkTokenExpiration()
                return axios.post(`${BASEURL}/order/confirm`, { id: data }, config)
                    .then(response => { return { status: response.data.status, data: response.data.data, err: response.data.err } })
                    .catch(err => { return { status: 400, data: null, err } })
            }
            case 'RECEIVE_PAYMENT': {
                const config = await checkTokenExpiration()
                return axios.post(`${BASEURL}/order/pay`, { rate: store.exchange_rate, amount: data.amount }, config)
                    .then(response => { return { status: response.data.status, data: response.data.data, err: response.data.err } })
                    .catch(err => { return { status: 400, data: null, err } })
            }
            case 'GET_PAYMENTS': {
                const config = await checkTokenExpiration()
                return axios.post(`${BASEURL}/order/get_pay`, {}, config)
                    .then(response => { return { status: response.data.status, data: response.data.data, err: response.data.err } })
                    .catch(err => { return { status: 400, data: null, err } })
            }
            case 'GET_TOTAL_PAID': {
                const config = await checkTokenExpiration()
                return axios.post(`${BASEURL}/order/get_total_pay`, {}, config)
                    .then(response => { return { status: response.data.status, data: response.data.data[0].total, err: response.data.err } })
                    .catch(err => { return { status: 400, data: null, err } })
            }
            default: return false
        }
    }

    const profileAction = async (action, data) => {
        switch (action) {
            case 'UPDATE_HOURS': {
                const config = await checkTokenExpiration()
                return axios.post(`${BASEURL}/store/updateWorkingHours`, { workingHours }, config)
                    .then(response => { return { status: response.data.status, data: response.data.data, err: response.data.err } })
                    .catch(err => { return { status: 400, data: null, err: err } })
            }
            case 'GET_HOURS': {
                const config = await checkTokenExpiration()
                axios.post(`${BASEURL}/store/getWorkingHours`, {}, config)
                    .then(response => {
                        const { status, data, err } = response.data
                        if (status) {
                            setWorkingHours(data)
                        } else
                            displayToast(err)
                    })
                    .catch(err => { console.log(err) })
                return true
            }
            default: return
        }
    }

    const tagsAction = async (action, data) => {
        switch (action) {
            case 'GET': {
                const config = await checkTokenExpiration()
                axios.post(`${BASEURL}/tag`, {}, config)
                    .then(response => {
                        const { status, data, err } = response.data
                        if (status) {
                            setTagList(c => { return data })
                        } else
                            displayToast(err)
                    })
                    .catch(err => { console.log(err) })
                return true
            }
            default: return false
        }
    }

    const addonsAction = async (action, data) => {
        switch (action) {
            case 'GET': {
                const config = await checkTokenExpiration()
                return axios.post(`${BASEURL}/addons/get`, {}, config)
                    .then(response => { return { status: response.data.status, data: response.data.data, err: response.data.err } })
                    .catch(err => { return { status: 400, data: null, err } })
            }
            default: return
        }
    }

    const usersAction = async (action, data) => {
        switch (action) {
            case 'CHANGE_PASS': {
                const config = await checkTokenExpiration()
                return axios.post(`${BASEURL}/store/changePass`, { data }, config)
                    .then(response => { return { status: response.data.status, data: response.data.data, err: response.data.err } })
                    .catch(err => { return { status: 400, data: null, err } })
            }
            default: return
        }
    }

    const InitialRender = () => {
        if (sessionStorage.getItem('ruser')) {
            storeAction('GET')
            groupAction('GET_CAT')
            groupAction('GET_SUBCAT')
            productAction('GET')
            profileAction('GET_HOURS')
            tagsAction('GET')
        }
    }

    const hideSplash = (ms = 0) => {
        delay(() => setShowSplash(false), ms)
    }

    useLayoutEffect(() => {
        if (isMobile)
            setShowMenu(false)
        InitialRender()
    }, [accessToken])

    useEffect(() => {
        if (window.location.pathname !== '/Login' || window.location.pathname !== '/Store/Login')
            if (refresh) {
                InitialRender()
                setRefresh(false)
            }
    }, [refresh])

    useEffect(() => {
        if (product.category)
            groupAction('GET_SUBCAT_BYCAT')
        else
            groupAction('GET_SUBCAT')
    }, [product.category])

    return (
        <DataContext.Provider value={{
            accessToken, setAccessToken,
            store, setStore,
            workingHours, setWorkingHours,
            product, setProduct,
            addons, setAddons,
            category, setCategory,
            subCategory, setSubCategory,
            showMenu, setShowMenu,
            refresh, setRefresh,
            displayToast,
            showLoading, setShowLoading,
            productList, categoryList, subCategoryList,
            tagList, setTagList,
            storeAction, groupAction, productAction, profileAction, tagsAction, orderAction, addonsAction, usersAction,
            hideSplash,
            decimalDigit, setDecimalDigit,
            percent, setPercent
        }}>
            {showSplash && <Spalsh />}
            {children}
        </DataContext.Provider>
    )
};

export default DataContext