import { Margins } from "../conponents/Margins";
import { CentralContainer, ContainerFooter, ContainerHeader } from '../conponents/Containers'
import { useParams } from "react-router-dom";
import { ProfileInput } from '../conponents/Input'
import useData from "../hooks/useData";
import { CurrencySelector } from "../conponents/Selectors";

export default function Setting() {
    const { username } = useParams()
    const { store, setStore, storeAction } = useData()

    return (
        <Margins username={username} active={'setting'}>
            <CentralContainer>
                <ContainerHeader username={username} page={'Setting'} showBack={true} />
                <div className="grid grid-rows md:grid-cols-2 w-full items-start">
                    <CurrencySelector label={'Currency'} id={'currrency symbol'} />
                    <ProfileInput type={'number'} label={'Rate'} id={'rate'} value={store.exchangerate} onChange={(e) => setStore({ ...store, exchangerate: e.target.value })} />
                    {/* <ProfileInput label={'Theme'} id={'theme'} /> */}
                </div>
                <ContainerFooter text={'Save'} onClick={() => storeAction('UPDATE')} />
            </CentralContainer>
        </Margins>
    )
}