import { useLayoutEffect, useState } from "react";
import { Input } from "../conponents/Input";
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import useData from "../hooks/useData";

export default function Login() {
    const URL = process.env.REACT_APP_URL
    const navigate = useNavigate()
    const { hideSplash } = useData()

    const [error, setError] = useState('')
    const [user, setUser] = useState('')
    const [pass, setPass] = useState('')
    const [required, setRequired] = useState({ user: false, pass: false })
    const { setAccessToken } = useData()

    const handleSubmit = () => {
        setError('')
        if (!user) {
            setRequired({ ...required, user: true })
            setError('Username are required')
            return
        }

        if (!pass) {
            setRequired({ ...required, pass: true })
            setError('Password are required')
            return
        }

        try {
            axios.post(`${URL}/user`, {
                username: user,
                password: pass
            })
                .then(response => {
                    if (response.data.status) {
                        sessionStorage.setItem('ruser', response.data.accessToken)
                        sessionStorage.setItem('refresh', response.data.refreshToken)
                        setAccessToken(response.data.accessToken)
                        navigate(`/Store/${user}`)
                    } else {
                        if (response.data.message)
                            setError(response.data.message)
                        else
                            setError('Internal Server Error')
                    }
                })
                .catch(error => {
                    setError(error)
                })
        } catch (err) {
            setError('err')
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit()
        }
    };

    useLayoutEffect(() => hideSplash())

    return (
        <div className="flex flex-col w-full bg-primary justify-center items-center px-0.5">
            <div className="flex flex-col bg-secondary md:min-w-[32rem] min-w-full p-5 rounded shadow">
                <div className="flex p-5">
                    <span className="text-black dark:text-color5 font-bold w-full text-center text-xl">Login to <span className="text-plant">Rapid</span></span>
                </div>
                <div className="flex flex-col md:flex-row p-5">
                    <Input
                        label={'Username'}
                        id='username'
                        value={user}
                        onChange={(e) => setUser(e.target.value)} onKeyDown={handleKeyDown} />
                    <Input
                        type='password'
                        label={'Password'}
                        id='password'
                        value={pass}
                        onChange={(e) => setPass(e.target.value)} onKeyDown={handleKeyDown} />
                </div>
                <div className="flex flex-col justify-center items-center p-5">
                    <span className="text-sm text-red italic">{error}</span>
                    <button
                        className="w-full bg-color4 p-2 font-bold outline-none"
                        onClick={handleSubmit}>
                        Login
                    </button>
                </div>
            </div>
        </div>
    )
}