import { Margins } from "../conponents/Margins";
import { useNavigate, useParams } from "react-router-dom";
import { ProfileInput, ProfileTextArea, TagInput } from '../conponents/Input'
import { CentralContainer, ContainerFooter, ContainerHeader } from "../conponents/Containers";
import useData from "../hooks/useData";
import { CoverInput } from "../conponents/ImageInput";
import { useEffect } from "react";
import { Facebook, Tiktok, Instagram } from "../assets/SocialMediaIcon";

export default function Profile() {
    const navigate = useNavigate()
    const { username } = useParams()
    const { store, setStore, storeAction, hideSplash, tagList } = useData()

    useEffect(() => {
        hideSplash(2000)
        // eslint-disable-next-line 
    }, [])

    return (
        <Margins username={username} active='profile'>
            <CentralContainer>
                <ContainerHeader page={'Profile'} username={username.slice(0, 10)} showBack={true} />
                <div className=" overflow-auto">
                    <div className="flex flex-col w-full justify-between -z-1">
                        <div className="flex p-1 pb-3 justify-end">
                            <button className="bg-plant p-3 font-bold rounded text-primary" onClick={() => navigate(`/Store/${username}/workinghours`)}>Working Hours</button>
                        </div>
                        <div className="mb-3">
                            <CoverInput cover={store.cover} setCover={(e) => { setStore({ ...store, cover: e }) }} />
                            {/* <ImageInput image={store.cover} setCover={(e) => { setStore({ ...store, image: e }) }} /> */}
                        </div>
                        <ProfileInput label={'Shop'} id={'shop name'} value={store.name ? store.name : ''} onChange={(e) => setStore({ ...store, name: e.target.value })} />
                        <ProfileTextArea label={'Description'} id={'shop description'} value={store.description ? store.description : ''} onChange={(e) => setStore({ ...store, description: e.target.value })} />
                        {/* <TagInput data={tagList} /> */}
                        <ProfileInput label={'Phone'} id={'phone number'} value={store.phone ? store.phone : ''} onChange={(e) => setStore({ ...store, phone: e.target.value })} />
                        <ProfileInput label={'Email'} id={'email address'} value={store.email ? store.email : ''} onChange={(e) => setStore({ ...store, email: e.target.value })} />
                        <ProfileInput label={'Address'} id={'address'} value={store.address ? store.address : ''} onChange={(e) => setStore({ ...store, address: e.target.value })} />
                        <ProfileInput label={'Location'} id={'location (long, latt)'} value={store.location ? store.location : ''} onChange={(e) => setStore({ ...store, location: e.target.value })} />
                    </div>
                    <div className="h-2 bg-color5 opacity-20 my-3"></div>
                    <div className="w-full flex flex-col">
                        <span className="w-full p-2 text-xl italic text-center">Social Media</span>
                        <div className="flex flex-col md:flex-row flex-wrap justify-between w-full gap-1 px-3">
                            <div className="relative">
                                <input className="w-full outline-none p-2 pl-7 bg-transparent border border-gray-light placeholder:text-sm placeholder:italic placeholder:text-gray-light" value={store.facebook ? store.facebook : ''} onChange={(e) => setStore({ ...store, facebook: e.target.value })} placeholder="Enter your Facebook link" />
                                <Facebook className={'w-8 h-8 px-1 absolute top-1/2 -translate-y-1/2'} />
                            </div>
                            <div className="relative">
                                <input className="w-full outline-none p-2 pl-7 bg-transparent border border-gray-light placeholder:text-sm placeholder:italic placeholder:text-gray-light" value={store.instagram ? store.instagram : ''} onChange={(e) => setStore({ ...store, instagram: e.target.value })} placeholder="Enter your Instagram link" />
                                <Instagram className={'w-8 h-8 px-1 absolute top-1/2 -translate-y-1/2'} />
                            </div>
                            <div className="relative">
                                <input className="w-full outline-none p-2 pl-7 bg-transparent border border-gray-light placeholder:text-sm placeholder:italic placeholder:text-gray-light" value={store.tiktok ? store.tiktok : ''} onChange={(e) => setStore({ ...store, tiktok: e.target.value })} placeholder="Enter your Tiktok link" />
                                <Tiktok className={'w-8 h-8 px-1 absolute top-1/2 -translate-y-1/2'} />
                            </div>
                        </div>
                    </div>
                </div>
                <ContainerFooter text='Save' onClick={() => storeAction('UPDATE')} />
            </CentralContainer>
        </Margins>
    )
}