
const clear = () => {
    caches.keys().then((names) => {
        names.forEach((name) => {
            caches.delete(name);
        });
    });
    console.log("Cache cleared..");
}
const clearCache = (version) => {
    const lastVersion = localStorage.getItem('last_v')
    if (!lastVersion || lastVersion != version) {
        clear()
        localStorage.setItem('last_v', version)
        return
    } else
        console.log('No updates detected')
}

export default clearCache