import { Navigate, Outlet } from "react-router-dom";

const useAuth = () => {
    const username = sessionStorage.getItem('ruser')
    var auth = false;

    if (username && username !== 'undefined') {
        auth = true;
    }

    const user = { authenticated: auth };
    return user.authenticated;
}

const Authentication = () => {
    const isAuth = useAuth();
    return isAuth ? <Outlet /> : <Navigate to="/Login" />;
}

export default Authentication;