import { useNavigate } from "react-router-dom"
import { CloseIco, ContactIco, DocumentIco, GroupIco, HomeIco, ListIco, MenuIco, NewIco, NotificationsIco, ProductIco, ProfileIco, SettingIco } from "../assets/Icons"
import useData from '../hooks/useData'
import { isMobile } from "react-device-detect"
import { useEffect, useRef, useState } from "react"
import { useOutsideFinder } from "../hooks/useOutsideFinder"
import { jwtDecode } from 'jwt-decode'
import { ContainerLoader } from "./Loaders"
import logo from '../assets/img/rapid_dark.png'
import axios from "axios"
import { io } from "socket.io-client"
import SocketClient from "./SocketClient"

const Menu = ({ children, isIco, onClick, className }) => {
    return (
        isIco
            ? <div
                className={"flex px-3 items-center justify-between text-color5 hover:text-plant cursor-pointer whitespace-nowrap " + className}
                onClick={onClick}>
                {children}
            </div>
            : <div
                className={"flex px-8 items-center justify-between text-color5 hover:bg-plant hover:text-primary cursor-pointer border-b border-primary whitespace-nowrap  " + className}
                onClick={onClick}>
                {children}
            </div>
    )
}

const LeftMenu = ({ children, onClick, className, ico }) => {
    return (
        <div
            className={"flex w-full py-3 px-1 text-color5 hover:bg-plant hover:text-primary cursor-pointer border-b border-primary " + className}
            onClick={onClick}>
            <span className="px-1">{ico && ico}</span>
            <span className={"px-1 md:block " + (!isMobile && 'hidden')}>{children}</span>
        </div>
    )
}

export const Header = ({ user, active }) => {
    const navigate = useNavigate()
    const { showMenu, setShowMenu } = useData()
    const [showPassModal, setShowPassModal] = useState(false)
    const [showOption, setShowOption] = useState(false)
    const profileRef = useRef()

    useOutsideFinder(() => setShowOption(false), profileRef)

    return (
        <div className="flex items-center bg-primary h-[6rem] z-[1]">
            <div className="flex items-center">
                {
                    isMobile
                        ? showMenu
                            ? <CloseIco className={'text-color5 cursor-pointer'} onClick={() => setShowMenu(!showMenu)} />
                            : <MenuIco className={'text-color5 cursor-pointer'} onClick={() => setShowMenu(!showMenu)} />
                        : <MenuIco className={'text-color5 cursor-pointer'} onClick={() => setShowMenu(!showMenu)} />
                }

            </div>
            <div className="flex-grow">
                <img src={logo} alt={'logo'} className="hidden xs:block w-48" />
            </div>
            <div className="relative z-1">
                <Menu className={(active === 'profile' ? 'text-plant' : '')} isIco={true} onClick={() => setShowOption(true)}>
                    {/* <ContactIco value={user} /> */}
                    <ProfileIco className={'w-10 h-10 rounded-full bg-secondary overflow-hidden'} />
                </Menu>
                {showOption && <div className="absolute z-[5] right-0 bg-primary p-1" ref={profileRef}>
                    <div className="flex whitespace-nowrap text-white-gray p-1 hover:bg-plant" onClick={() => navigate(`/Store/${user}/profile`)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                        </svg>
                        <label className="px-2">Profile</label></div>
                    <div className="flex whitespace-nowrap text-white-gray p-1 hover:bg-plant" onClick={() => setShowPassModal(!showPassModal)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                        </svg>
                        <label className="px-2">Change Password</label></div>
                    <div className="flex whitespace-nowrap text-white-gray p-1 hover:bg-plant" onClick={() => { sessionStorage.clear(); localStorage.clear(); navigate('/Login') }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                        </svg>
                        <label className="px-2">Logout</label>
                    </div>
                    <ChangePassword show={showPassModal} setShow={(e) => setShowPassModal(false)} />
                </div>}
            </div>
        </div>
    )
}

export const LeftSide = ({ user, active }) => {
    const navigate = useNavigate()
    const { showMenu, setRefresh } = useData()
    const [menuExpand, setMenuExpand] = useState(false)
    const menuRef = useRef()

    const handleExpand = (e) => { setMenuExpand(!menuExpand) }

    const navigateTo = (url) => {
        setRefresh(true)
        navigate(url)
        setMenuExpand(false)
    }

    // useOutsideFinder(() => setMenuExpand(false), menuRef)
    return (
        showMenu
            ? <div className={"lg:flex hidden flex-col bg-primary md:w-[10rem] h-full"}>
                <LeftMenu
                    className={(active === 'home' ? 'bg-plant text-primary' : '')}
                    onClick={() => navigateTo(`/Store/${user}`)}
                    ico={<HomeIco />}>
                    Home
                </LeftMenu>
                <LeftMenu
                    className={(active === 'menu' ? 'bg-plant text-primary' : '')}
                    onClick={handleExpand}
                    ico={<ProductIco />}>
                    Menu
                </LeftMenu>
                <div ref={menuRef}>
                    {
                        menuExpand
                            ? <div className="bg-secondary p-2">
                                <LeftMenu ico={<NewIco />} className={'text-sm'} onClick={() => navigateTo(`/Store/${user}/addmenu`)}>New</LeftMenu>
                                <LeftMenu ico={<GroupIco />} className={'text-sm'} onClick={() => navigateTo(`/Store/${user}/listmenu`)}>List</LeftMenu>
                                <LeftMenu ico={<ListIco />} className={'text-sm'} onClick={() => navigateTo(`/Store/${user}/pricelist`)}>Price List</LeftMenu>
                                {/* <LeftMenu ico={<ListIco />} className={'text-sm'} onClick={() => navigateTo(`/Store/${user}/group`)}>Groups</LeftMenu> */}
                            </div>
                            : <></>
                    }
                </div>

                <LeftMenu
                    className={(active === 'report' ? 'bg-plant text-primary' : '')}
                    onClick={() => { navigateTo(`/Store/${user}/report`) }}
                    ico={<DocumentIco />}>
                    Report
                </LeftMenu>
                <LeftMenu
                    className={(active === 'order' ? 'bg-plant text-primary' : '')}
                    onClick={() => navigateTo(`/Store/${user}/order`)}
                    ico={<DocumentIco />}>
                    Order
                </LeftMenu>
                {/* <LeftMenu
                    className={(active === 'setting' ? 'bg-plant text-primary' : '')}
                    onClick={() => navigateTo(`/Store/${user}/setting`)}
                    ico={<SettingIco />}>
                    Setting
                </LeftMenu> */}
            </div >
            : <></>
    )
}

export const LeftSideMobile = ({ user, active }) => {
    const navigate = useNavigate()
    const [menuExpand, setMenuExpand] = useState(false)
    const menuRef = useRef()
    const { showMenu, setShowMenu } = useData()

    const handleExpand = (e) => { setMenuExpand(!menuExpand) }

    const navigateTo = (url) => {
        setShowMenu(false)
        setMenuExpand(false)
        navigate(url)
    }

    useOutsideFinder(() => setMenuExpand(false), menuRef)
    useOutsideFinder(() => setShowMenu(false), menuRef)
    return (
        showMenu
            ? <div className={"absolute flex flex-col bg-secondary w-full z-[50] "} ref={menuRef}>
                <LeftMenu
                    className={(active === 'home' ? 'bg-plant text-primary' : '')}
                    onClick={() => navigateTo(`/Store/${user}`)}
                    ico={<HomeIco />}>
                    Home
                </LeftMenu>
                <LeftMenu
                    className={(active === 'menu' ? 'bg-plant text-primary' : '')}
                    onClick={handleExpand}
                    ico={<ProductIco />}>
                    Menu
                </LeftMenu>
                <div ref={menuRef}>
                    {
                        menuExpand
                            ? <div className="bg-secondary p-2">
                                <LeftMenu ico={<NewIco />} className={'text-sm'} onClick={() => navigateTo(`/Store/${user}/addmenu`)}>New</LeftMenu>
                                <LeftMenu ico={<GroupIco />} className={'text-sm'} onClick={() => navigateTo(`/Store/${user}/listmenu`)}>List</LeftMenu>
                                <LeftMenu ico={<GroupIco />} className={'text-sm'} onClick={() => navigateTo(`/Store/${user}/pricelist`)}>Price List</LeftMenu>
                                {/* <LeftMenu ico={<ListIco />} className={'text-sm'} onClick={() => navigateTo(`/Store/${user}/group`)}>Groups</LeftMenu> */}
                            </div>
                            : <></>
                    }
                </div>
                <LeftMenu
                    className={(active === 'report' ? 'bg-plant text-primary' : '')}
                    onClick={() => { navigateTo(`/Store/${user}/report`) }}
                    ico={<DocumentIco />}>
                    Report
                </LeftMenu>
                <LeftMenu
                    className={(active === 'order' ? 'bg-plant text-primary' : '')}
                    onClick={() => navigateTo(`/Store/${user}/order`)}
                    ico={<DocumentIco />}>
                    Order
                </LeftMenu>
                {/* <LeftMenu
                    className={(active === 'setting' ? 'bg-plant text-primary' : '')}
                    onClick={() => navigateTo(`/Store/${user}/setting`)}
                    ico={<SettingIco />}>
                    Setting
                </LeftMenu> */}
            </div >
            : <></>

    )
}

export const Footer = () => {
    return (
        <div id="footer" className="bottom-0 flex w-full bg-primary p-3 justify-center text-sm text-color5">
            <div
                className="text-sm">
                Developed by <span className="text-plant italic">Rapid Development</span>
            </div>
        </div>
    )
}

export const Margins = ({ children, active, className }) => {
    const username = jwtDecode(sessionStorage.getItem('ruser')).username
    const { hideSplash, store } = useData()

    useEffect(() => {
        window.scrollTo(0, 0)
        hideSplash()
    }, [])

    return (
        <div className={"relative flex flex-col w-full h-screen max-w-screen overflow-auto z-[1] " + className}>
            {/* {!isMobile && <SocketClient />} */}
            <ContainerLoader />
            <Header user={username} active={active} />
            <div className="flex w-full h-full overflow-auto relative bg-black z-1">
                {!isMobile ? <LeftSide user={username} active={active} /> : <LeftSideMobile user={username} active={active} />}
                <div className="flex items-center justify-center w-full min-h-full py-2 px-0 md:px-2 overflow-y-auto z-1">
                    {children}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export const ChangePassword = ({ show, setShow }) => {
    const [oldPass, setOldPass] = useState('')
    const [newPass, setNewPass] = useState('')
    const [confirmPass, setConfirmPass] = useState('')
    const { usersAction } = useData()

    const changePassword = () => {
        if (!oldPass)
            return console.log('Old password is empty')
        if (!newPass)
            return console.log('New password is emoty')
        if (!confirmPass)
            return console.log('Confirm password is empty')

        if (newPass != confirmPass)
            return console.log('Confirm password doesn\'t match new password')

        usersAction('CHANGE_PASS', { oldPass, newPass })
            .then(response => {
                console.log(response)
            })
    }

    return (
        show
            ? <div className="fixed inset-0">
                <div className="w-full h-full flex flex-col items-center justify-center bg-transparent">
                    <div className="flex flex-col max-w-3xl max-h-3xl bg-secondary p-10 text-white-gray">
                        <div className="flex w-full text-2xl text-white-gray border-b p-1 mb-5">Change Password</div>
                        <div className="flex items-center py-1">
                            <label className="px-1 flex-1 ">Old Password</label>
                            <input type="password" className="flex-1 outline-none bg-primary p-2" value={oldPass} onChange={(e) => setOldPass(e.target.value)} />
                        </div>
                        <div className="flex items-center py-1">
                            <label className="px-1 flex-1 ">New Password</label>
                            <input type="password" className="flex-1 outline-none bg-primary p-2" value={newPass} onChange={(e) => setNewPass(e.target.value)} />
                        </div>
                        <div className="flex items-center py-1">
                            <label className="px-1 flex-1 ">Confirm Password</label>
                            <input type="password" className="flex-1 outline-none bg-primary p-2" value={confirmPass} onChange={(e) => setConfirmPass(e.target.value)} />
                        </div>
                        <div className="flex justify-end pt-5">
                            <button className="bg-plant p-2 px-4 font-bold text-primary" onClick={changePassword}>Save</button>
                            <button className="bg-secondary p-2 px-4 font-bold text-primary" onClick={() => setShow(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            : null
    )
}