import { useEffect } from 'react';
import { toast } from 'react-hot-toast';

export default function CustomToast({ message, t }) {
    const TOAST_TIMER = process.env.REACT_APP_HIDE_TOAST

    useEffect(() => {
        const timer = setTimeout(() => {
            toast.dismiss(t.id);
        }, TOAST_TIMER);

        return () => clearTimeout(timer);
    }, [t.id]);

    const handleClose = () => {
        toast.dismiss(t.id);
    };

    return (
        <div className={`${t.visible ? 'animate-enter' : 'animate-leave'} max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}>
            <div className="flex-1 w-0 p-4">
                <div className="flex items-start">
                    <div className="ml-3 flex-1">
                        <p className="mt-1 text-sm text-gray-500">{message}</p>
                    </div>
                </div>
            </div>
            <div className="flex border-l border-gray-200">
                <button onClick={handleClose} className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                    Close
                </button>
            </div>
        </div>
    );
}