import { Margins } from '../conponents/Margins'
import { CentralContainer, ContainerFooter, ContainerHeader } from '../conponents/Containers'
import { useLocation, useParams } from 'react-router-dom'
import { ProfileInput, ProfileTextArea } from '../conponents/Input'
import useData from '../hooks/useData'
import { MenuImage } from '../conponents/ImageInput'
import { GroupSelector } from '../conponents/Selectors'
import Addons from '../conponents/Addons'
import { useEffect, useLayoutEffect, useState } from 'react'
import { NewIco } from '../assets/Icons'
import { GenerateUniqueId } from '../hooks/ConvertNumber'

export default function Menu() {
    const { username } = useParams()
    const { state } = useLocation()
    const { store, product, productAction, addonsAction, setShowLoading, hideSplash, displayToast, groupAction } = useData()
    const [newProduct, setNewProduct] = useState(product)
    const [addonsList, setAddonsList] = useState([])
    const [isUploding, setIsUploading] = useState(false)
    const [selectedAddons, setSelectedAddons] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [show, setShow] = useState(false)

    const checkValidId = () => {
        if (state) {
            if (state.id) {
                if (state.id > 0) {
                    return true
                }
            }
        }
        return false
    }

    const handleAddAddon = async (id) => {
        if (id) {
            const result = selectedAddons?.map(x => {
                if (x.id === id) {
                    const updatedOptions = [...x.options, { id: GenerateUniqueId(), name: null, price: null }];
                    return { ...x, options: updatedOptions };
                }
                else {
                    return { ...x };
                }
            });
            setSelectedAddons(result);
        }
    }

    const handleChangeAddonsDetails = (addon, option, value, key) => {
        if (addon && option) {
            const result = selectedAddons?.map(x => {
                if (x.id === addon.id) {
                    const updatedOptions = x.options.map(op => {
                        if (op.id === option.id) {
                            return key === 'NAME' ? { ...op, addonId: addon.id, name: value } : { ...op, addonId: addon.id, price: value }
                        }
                        return op;
                    });
                    return { ...x, options: updatedOptions };
                }
                return { ...x };
            });
            setSelectedAddons(result);
        }
    }

    const handleSubmit = () => {
        let alert = ''

        if (isUploding)
            return displayToast('Wait until uploading finish!')
        if (!newProduct?.name)
            return displayToast('Name cannot be null!')

        setShowLoading(true)
        if (checkValidId()) {
            console.log('Update')
            productAction('UPDATE', { newProduct, selectedAddons })
                .then(response => {
                    const { status, data, err } = response
                    if (status)
                        alert = data
                    else
                        alert = err
                })
        } else {
            productAction('ADD', { product: newProduct, addons: selectedAddons })
                .then(response => {
                    const { status, data, err } = response
                    if (status) {
                        setNewProduct(product)
                        alert = data
                    }
                    else
                        alert = err
                })
        }
        setTimeout(() => {
            setShowLoading(false)
            if (alert.length > 0)
                displayToast(alert)
        }, 1200)
    }

    const init = async () => {
        setShowLoading(true)
        if (checkValidId()) {
            productAction('GET_BY_ID', { updateId: state.id })
                .then(response => {
                    console.log(response)
                    setTimeout(() => setShowLoading(true), [1200])
                    setNewProduct(response.product)
                    setTimeout(() => setShowLoading(false), [1200])
                    if (response.addons && response.addons.length > 0) {
                        setSelectedAddons(response.addons.filter(c => { return c.options.length > 0 }))
                    }
                })
            addonsAction('GET')
                .then(response => {
                    const { status, data, err } = response
                    if (status)
                        setAddonsList(data)
                    else
                        displayToast(err)
                })
                .catch(err => console.log(err))
        }
        else {
            await productAction('CLEAR')
                .then(response => {
                    setNewProduct(response)
                })
            addonsAction('GET')
                .then(response => {
                    const { status, data, err } = response
                    if (status) {
                        setAddonsList(data)
                    }
                    else
                        displayToast(err)
                })
                .catch(err => console.log(err))
        }
        groupAction('GET_CAT_PRODUCT')
            .then((response) => {
                const { status, data, err } = response
                if (status)
                    setCategoryList(data)
                else
                    displayToast(err)
            })
            .catch(err => displayToast(err))
        setTimeout(() => setShowLoading(false), 1200)
    }

    useLayoutEffect(() => {
        init()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        hideSplash()
        // eslint-disable-next-line
    }, [])
    // console.log(newProduct)

    return (
        <Margins active={'menu'}>
            <CentralContainer>
                <ContainerHeader page={'Menu'} username={username} item={''} showBack={true} />
                <div className='flex-grow flex flex-col w-full overflow-auto'>
                    <div className='flex w-full items-center justify-center md:justify-start'>
                        <MenuImage menu={newProduct.image ? newProduct.image : ''} setMenu={(e) => { setNewProduct({ ...newProduct, image: e }) }} className={'w-44 h-44 rounded-full object-fill'} isUploding={isUploding} setIsUploading={setIsUploading} />
                    </div>
                    <div className='grid grid-rows md:grid-cols-2 w-full items-start'>
                        <ProfileInput
                            label={'Title'}
                            id={'product title'} value={newProduct.name} onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })} />
                        <ProfileTextArea
                            label={'Description'}
                            id={'product description'} value={newProduct.description} onChange={(e) => setNewProduct({ ...newProduct, description: e.target.value })} />
                        {/* <GroupSelector label={'Category'} id={'category'} data={categoryList} group={'CAT'} /> */}
                        <GroupSelector label={'Category'} id={'category'} data={categoryList} group={'SUBCAT'} value={newProduct.subcategory ? newProduct.subcategory : ''} onChange={(e) => setNewProduct({ ...newProduct, subcategory: e, category: e })} />
                        <ProfileInput
                            type={'number'}
                            label={'Price'}
                            id={'product price'} value={newProduct.price} onChange={(e) => setNewProduct({ ...newProduct, price: e.target.value })} />
                        <ProfileInput
                            type={'number'}
                            label={'Discount'}
                            id={'product discount %'} value={newProduct.discount ? newProduct.discount : ''} onChange={(e) => setNewProduct({ ...newProduct, discount: e.target.value })} />
                        <div className='flex items-center w-full h-full p-2'>
                            <input id='active' type='checkbox' className='cursor-pointer' checked={newProduct.active === 1 ? true : false} onChange={(e) => setNewProduct({ ...newProduct, active: e.target.checked ? 1 : 0 })} />
                            <label className='px-2 cursor-pointer' htmlFor='active'>Active</label>
                        </div>
                    </div>
                    <NewIco className="text-plant p-1" title={'Ingredients'} onClick={() => setShow(true)} />
                    {show && <Addons addonsList={addonsList} selected={selectedAddons} setSelected={setSelectedAddons} setShow={setShow} />}
                    <div className=''>
                        {
                            selectedAddons
                                ? selectedAddons.map((x, index) => {
                                    return (
                                        <div key={index} className='w-full flex flex-col p-5 cursor-pointer'>
                                            <div className='flex bg-secondary p-1 px-5' onClick={() => handleAddAddon(x.id)}>
                                                <span className='flex-grow text-xl text-plant-light'>{x.name}</span>
                                                <NewIco className={'text-plant-light'} />
                                            </div>
                                            <div className=''>
                                                {
                                                    x.options
                                                        ? x.options?.map((y, index) => {
                                                            return <div key={index} className='border-b border-color6'>
                                                                <div className="flex flex-col w-full">
                                                                    <div className='flex w-full p-2 items-center'>
                                                                        <label className="w-1/3 test-green">Name</label>
                                                                        <div className='relative w-2/3'>
                                                                            <input className="w-full bg-transparent border border-gray-light p-2 pr-10 placeholder:text-sm placeholder:text-gray-light placeholder:italic outline-none focus:border-plant-dark" type="text" placeholder='Enter a name' value={y.name ? y.name : ''} onChange={(e) => handleChangeAddonsDetails(x, y, e.target.value, 'NAME')} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex w-full p-2 items-center'>
                                                                        <label className="w-1/3 test-green">Price</label>
                                                                        <div className='relative w-2/3'>
                                                                            <input className="w-full bg-transparent border border-gray-light p-2 pr-10 placeholder:text-sm placeholder:text-gray-light placeholder:italic outline-none focus:border-plant-dark" type="number" placeholder='+ price' value={y.price ? y.price : ''} onChange={(e) => handleChangeAddonsDetails(x, y, e.target.value, 'PRICE')} />
                                                                            <span className="absolute top-1/2 -translate-y-1/2 right-2 font-bold">{store.currency}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })
                                                        : null
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                                : null
                        }
                    </div>
                </div>
                <ContainerFooter text={'Save'} onClick={handleSubmit} />
            </CentralContainer>
        </Margins>
    )
}