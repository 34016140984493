import './App.css'
import { useTheme } from './hooks/useTheme'
import AppRouting from './AppRouting';
import { Toaster } from 'react-hot-toast';
import { useEffect } from 'react';
import packageJson from '../package.json';
import clearCache from './utils/clearCache';

function App() {
  let theme = useTheme()

  useEffect(() => {
    clearCache(packageJson.version)
  }, [])

  return (
    <div className={theme + " flex bg-secondary min-w-screen min-h-screen w-screen h-screen"}>
      <Toaster position="top-right" />
      <AppRouting />
    </div >
  );
}

export default App;
