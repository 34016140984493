import { useParams } from "react-router-dom";
import { Margins } from "../conponents/Margins";
import { Container, ContainerHeader } from "../conponents/Containers";
import { Table } from "../conponents/Table";
import useData from "../hooks/useData";
import { useEffect, useLayoutEffect, useState } from "react";

const Categories = ({ setSelectedCat }) => {
    const { groupAction, displayToast } = useData()
    const [selected, setSelected] = useState(-1)
    const [subCategoryList, setSubCategory] = useState([])

    useLayoutEffect(() => {
        groupAction('GET_CAT_PRODUCT')
            .then(response => {
                // console.log(response)
                const { status, data, err } = response
                if (status)
                    setSubCategory(data.filter(x => { return x.items > 0 }))
                else
                    displayToast(err)
            })
            .catch(err => displayToast(err))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (selected)
            setSelectedCat(selected)
        // eslint-disable-next-line
    }, [selected])
    return (
        <div className="w-full max-w-full flex flex-row overflow-hidden overflow-x-auto h-10 items-center py-3">
            <button className={"min-w-[10rem] p-2 h-[50px] " + (selected === -1 || !selected ? 'bg-plant text-primary font-bold' : 'bg-primary text-color5')} onClick={() => setSelected(-1)}>All</button>
            {subCategoryList ? (
                subCategoryList.map((x) => {
                    return (
                        <button
                            key={x.id}
                            className={"min-w-[10rem] p-2 " + (selected === x.id ? 'bg-plant text-primary font-bold' : 'bg-primary text-color5')}
                            onClick={() => setSelected(x.id)}
                        >
                            {x.name}
                        </button>
                    );
                })
            ) : null}
        </div>
    )
}

export default function MenuList() {
    const { username } = useParams()
    const [selected, setSelected] = useState(-1)

    return (
        <Margins username={username}>
            <Container>
                <div className="flex flex-col text-color5 md:px-10 pb-5 mt-5 w-full ">
                    <ContainerHeader username={username} page={'List of product'} showBack={true} />
                    <Categories setSelectedCat={(e) => setSelected(e)} />
                    <Table selectedCat={selected} />
                </div>
            </Container>
        </Margins>
    )
}