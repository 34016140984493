import { useNavigate, useParams } from "react-router-dom";
import { Container, ContainerHeader } from "../conponents/Containers";
import { Margins } from "../conponents/Margins";
import useData from "../hooks/useData";
import { useEffect, useState } from "react";
import { formatDateTime } from "../hooks/ConvertTime";

const Card = ({ orderList, title, value, color }) => {
    const { store, orderAction } = useData()
    const [pay, setPay] = useState(0)

    const getByStatus = (status) => {
        switch (status) {
            case 'complete': return orderList?.filter(x => x.name === 'Complete').length
            case 'service_charge': {
                const sumOfServiceCharge = orderList?.reduce((total, transaction) => {
                    if (transaction.status_id === 2) {
                        return total + (transaction.price * transaction.quantity);
                    }
                    return total;
                }, 0)
                return sumOfServiceCharge.toLocaleString()
            }
            case 'due_amount': {
                const sumOfServiceCharge = orderList?.reduce((total, transaction) => {
                    if (transaction.status_id === 2) {
                        return total + (transaction.price * transaction.quantity);
                    }
                    return total;
                }, 0)
                return (sumOfServiceCharge - (sumOfServiceCharge * store.percentage / 100)).toLocaleString()
            }
            case 'percentage': {
                return store.percentage + ' %'
            }
            case 'receive_amount': {
                return pay
            }
            default: return 0
        }
    }

    useEffect(() => {
        orderAction('GET_TOTAL_PAID')
            .then(async (response) => {
                const { status, data, err } = response
                if (status)
                    setPay(data)
            })
            .catch(err => { return })
    })

    return (
        <div className={`flex flex-col w-[15rem] h-[10rem] ${color}`}>
            <span className="flex-grow flex items-center justify-center text-5xl">{getByStatus(value)}</span>
            <span className={`flex items-center h-[3rem] px-2 `}>{title}</span>
        </div>
    )
}

export default function Report() {
    const navigate = useNavigate()
    const { username } = useParams()
    const { store, orderAction, hideSplash, displayToast, setShowLoading } = useData()
    const [orderList, setOrderList] = useState([])
    const [orderItemsList, setOrderItemsList] = useState([])

    const thStyle = 'text-left font-bold p-2 border-b border-color5 text-white-gray'
    const tdStyle = 'p-2 border-b border-gray-light'

    useEffect(() => {
        hideSplash()
        setShowLoading(true)
        orderAction('GET')
            .then(async (response) => {
                const { status, data, err } = response
                if (status) {
                    await setOrderList(data)
                } else {
                    hideSplash(1200)
                    displayToast(err)
                }
                setTimeout(() => setShowLoading(false), 1200)

            })
            .catch(err => console.log(err))

        orderAction('GET_ORDER_ITEMS_STORE')
            .then(async (response) => {
                const { status, data, err } = response
                if (status) {
                    await setOrderItemsList(data)
                } else {
                    hideSplash(1200)
                    displayToast(err)
                }
                setTimeout(() => setShowLoading(false), 1200)
            })
        // eslint-disable-next-line
    }, [])

    return (
        <Margins active={'report'} >
            <Container >
                <ContainerHeader page={'Report'} username={username} />
                <div className="flex md:flex-row flex-col flex-wrap gap-2 items-center justify-center">
                    <Card title={'Complete'} value={'complete'} orderList={orderList} color={'border-b border-b-red'} />
                    <Card title={'Total Sales'} value={'service_charge'} orderList={orderItemsList} color={'border-b border-b-green'} />
                    <Card title={'Total Due'} value={'due_amount'} orderList={orderItemsList} color={'border-b border-b-color7'} />
                    <Card title={'Total Received'} value={'receive_amount'} orderList={orderItemsList} color={'border-b border-b-color7'} />
                    <Card title={'Percentage'} value={'percentage'} orderList={orderItemsList} color={'border-b border-b-color4'} />
                </div>
                <div className="flex flex-col md:flex-row my-3 items-center">
                    <button className="bg-plant text-primary p-2 hover:opacity-60 w-fit h-fit" onClick={() => navigate(`/store/${store.username}/payments`)}>Payments</button>
                </div>
                <div className="w-full overflow-auto">
                    <table className="w-full overflow-auto">
                        <thead>
                            <tr>
                                <th className={thStyle}>#</th>
                                <th className={thStyle}>Pickup location</th>
                                <th className={thStyle}>Delivery location</th>
                                <th className={thStyle}>Created time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orderList && orderList?.length
                                    ? orderList?.map((x, index) => {
                                        return <tr key={index}>
                                            <td className={tdStyle}>{x.id}</td>
                                            <td className={tdStyle}>{x.pickup_location}</td>
                                            <td className={tdStyle}>{x.delivery_location}</td>
                                            {/* <td className={tdStyle}>{store.currency} {x.service_charge}</td> */}
                                            <td className={tdStyle}>{formatDateTime(x.created_at)}</td>
                                        </tr>
                                    })
                                    : null
                            }
                        </tbody>
                    </table>
                    {
                        orderList && orderList?.length === 0
                            ? <div className="italic text-sm text-white-gray w-full flex justify-center items-center p-1">No data to show</div>
                            : null
                    }
                </div>
            </Container>
        </Margins>
    )
}