import { useEffect, useLayoutEffect, useState } from "react";
import { Container, ContainerHeader } from "../conponents/Containers";
import { Margins } from "../conponents/Margins";
import useData from "../hooks/useData";
import { useNavigate, useParams } from "react-router-dom";
import { ProfileInput, SearchInput } from "../conponents/Input";
import { formatDistanceToNow, parseISO } from "date-fns";
import icon from '../assets/img/logo_black.png'
import { formatDateTime } from "../hooks/ConvertTime";

const Order = ({ order }) => {
    const { username } = useParams()
    const navigate = useNavigate()
    const { orderAction, displayToast } = useData()
    const statusToShow = ["Pending", "Confirmed", "Complete"];
    const isInArray = statusToShow.includes(order.name);

    const color = () => {
        if (order.status_id === 2) return 'bg-green text-primary'
        else if (order.is_confirmed === '0' || order.is_confirmed === 0) return 'bg-red text-primary'
        else return 'bg-color4 text-primary'
    }

    const showDetails = () => {
        orderAction('GET_ORDER_ITEMS', order.id)
            .then(async response => {
                const { status, data, err } = response
                if (status) {
                    navigate(`/store/${username}/orderdetails`, { state: { order: order.id } })

                } else {
                    displayToast(err ? err : 'Internal Error')
                }
            })
            .catch(err => {
                displayToast(err)
            })
    }

    return (
        <div className='w-[100%] md:w-[30%] xl:max-w-[18rem] h-[15rem] p-3 cursor-pointer' onClick={() => showDetails()}>
            <div className="relative bg-primary p-4 border border-secondary rounded-md shadow-md mb-4">
                <div className="w-[5rem] h-[5rem] p-4 rounded-full bg-white opacity-80 flex items-center justify-center">
                    <img src={icon} alt="logo" className="w-full h-full" />
                </div>
                <div className="flex justify-between items-center py-3">
                    <div className="text-white font-bold text-lg">Order #{order.id}</div>
                    {
                        isInArray && <div className={`${color()} p-1 text-sm font-bold rounded-md`}>
                            {
                                order.status_id === 2
                                    ? 'Completed'
                                    : order.is_confirmed === '0' || order.is_confirmed === 0
                                        ? 'Pending'
                                        : 'Confirmed'
                            }
                        </div>
                    }
                </div>
                <div className="text-color8">Customer: {formatDateTime(order.created_at)}</div>
                <div className="absolute top-3 right-3 text-[min(12vw, 1rem)] text-color8 italic flex justify-end p-1">{formatDistanceToNow((parseISO(order.created_at)))}</div>
            </div>
        </div>
    )
}

export default function OrderList() {
    const { username } = useParams()
    const { store, setStore, storeAction, orderAction, setShowLoading, displayToast, hideSplash, refresh, setRefresh } = useData()
    const [orderList, setOrderList] = useState([])
    const [selectedGrp, setSelectedGrp] = useState('pending')
    const [searchKey, setSearchKey] = useState('')
    const unselectedColor = 'text-white-gray bg-gray-light'

    const filterdByOrders = orderList?.filter(c => { return c.id.toString().includes(searchKey.toString()) })
        .sort((a, b) => { return new Date(b.created_at) - new Date(a.created_at); });

    const getorderList = () => {
        orderAction('GET')
            .then(async response => {
                await setShowLoading(true)
                const { status, data, err } = response
                if (status) {
                    setOrderList(data)

                } else { displayToast(err) }
                setTimeout(() => setShowLoading(false), 1200);
            })
    }

    useLayoutEffect(() => {
        setShowLoading(true)
        hideSplash()
        getorderList()
        setTimeout(() => setShowLoading(false), 1200)
        // eslint-disable-next-line
    }, [])

    const handleActiveButton = (e) => {
        setSelectedGrp(e.target.id)
    }

    useEffect(() => {
        if (refresh) {
            setRefresh(false)
            getorderList()
        }
    }, [refresh])

    return (
        <Margins active={'order'}>
            <Container>
                <ContainerHeader username={username} showBack={true} page={'Order list'} />
                <div className="relative flex flex-row items-center justify-end md:w-1/4">
                    <ProfileInput type={'number'} label={'Rate'} id={'rate'} value={store.exchange_rate ? store.exchange_rate : 0} onChange={(e) => setStore({ ...store, exchange_rate: e.target.value })} >
                        <button className="bg-black p-2 text-white-gray hover:bg-plant hover:text-primary rounded-r overflow-hidden absolute right-[1px] top-1/2 -translate-y-1/2" onClick={() => storeAction('UPDATE')}>Update</button>
                    </ProfileInput>
                </div>
                <div className="flex justify-between ">
                    <button id="pending" className={`w-full text-white-gray border border-primary font-semibold p-2 focus:text-primary ${selectedGrp === 'pending' ? 'bg-red text-primary' : unselectedColor}`} onClick={handleActiveButton}>Pending</button>
                    <button id="confirmed" className={`w-full text-white-gray border border-primary font-semibold p-2 focus:text-primary ${selectedGrp === 'confirmed' ? 'bg-color4 text-primary' : unselectedColor}`} onClick={handleActiveButton}>Confirmed</button>
                    <button id="complete" className={`w-full text-white-gray border border-primary font-semibold p-2 focus:text-primary ${selectedGrp === 'complete' ? 'bg-green text-primary' : unselectedColor}`} onClick={handleActiveButton}>Complete</button>
                    <button id="all" className={`w-full  border border-primary font-semibold p-2  focus:text-primary ${selectedGrp === 'all' ? 'bg-plant text-primary' : unselectedColor}`} onClick={handleActiveButton}>All</button>
                </div>
                <div className="w-1/3">
                    <SearchInput id={'order'} type={'text'} value={searchKey} onChange={(e) => setSearchKey(e.target.value)} onRemove={() => setSearchKey('')} />
                </div>
                <div className={'flex flex-wrap justify-center flex-row w-full h-full'}>
                    {


                        filterdByOrders?.length > 0
                            ? filterdByOrders.filter(x => {
                                if (selectedGrp === 'pending')
                                    return (x.is_confirmed === '0' || x.is_confirmed === 0) && x.status_id !== 2
                                else if (selectedGrp === 'confirmed')
                                    return (x.is_confirmed === '1' || x.is_confirmed === 1) && x.status_id !== 2
                                else if (selectedGrp === 'complete')
                                    return x.status_id === 2
                                else
                                    return x
                            }).map((x, index) => { return <Order key={index} order={x} /> })
                            : <span>No data to show</span>

                    }
                </div>
            </Container>
        </Margins>

    )
}