import { useEffect } from "react"
import useData from "../hooks/useData"

export default function Support() {
    const { hideSplash } = useData()

    useEffect(() => {
        hideSplash()
    })

    return (
        <div className="flex justify-center items-center w-full text-2xl">
            <div className="flex flex-col justify-center text-white-gray bg-primary p-5 h-fit">
                <h1 className="text-plant text-3xl">Contact Us</h1>
                <p>If you have any questions or concerns, please feel free to contact us:</p>
                <ul>
                    <li><strong>Email:</strong> <a href="mailto:support@rapidleb.com">support@rapidleb.com</a></li>
                    <li><strong>Phone:</strong> +961 76 478042</li>
                    <li><strong>Address:</strong> Tyre, Bzeih Building, GF</li>
                </ul>
            </div>
        </div>
    )
}