import logo from '../assets/img/logo.png'

export const Spalsh = () => {

    return (
        <div className="fixed w-full min-w-screen h-full min-h-full bg-primary flex justify-center items-center z-[100]">
            <img src={logo} className='animate-logo max-w-[12vw]' alt='Rapidleb Co.' />
        </div>
    )
}

export const ImageSpalsh = () => {

    return (
        <div className="absolute w-full h-full bg-primary flex justify-center items-center z-[99]">
            <img src={logo} className='animate-logo max-w-[3vw]' alt='Rapidleb Co.' />
        </div>
    )
}

export const ListSpalsh = () => {

    return (
        <div className="w-[6rem] h-[6rem] flex justify-center items-center z-[99]">
            <img src={logo} className='animate-logo min-w-[1rem] max-w-[3rem]' alt='Rapidleb Co.' />
        </div>
    )
}