import { useEffect, useRef, useState } from "react"
import { useOutsideFinder } from "../hooks/useOutsideFinder"
import { convert24to12 } from "../hooks/ConvertTime"
import { cloneDeep } from 'lodash'
import { CloseIco, SearchIco } from "../assets/Icons"

export const Input = ({ type, label, id, value, onChange, onKeyDown }) => {

    return (
        <div
            className={"flex flex-col p-2 bg-transparent text-white dark:text-color5"}>
            <label
                className="p-1 cursor-pointer"
                htmlFor={id}>{label}</label>
            <input
                type={type ? type : 'text'}
                id={id}
                className="outline-none bg-transparent placeholder:italic placeholder:text-sm border border-transparent focus:border-color5 rounded p-2"
                value={value}
                placeholder={`Enter your ${id}`}
                onChange={onChange}
                onKeyDown={onKeyDown} />
        </div>
    )
}

export const ProfileInput = ({ type, label, id, value, onChange, className, children, prefix }) => {

    return (
        <div className={"flex md:flex-row flex-col md:items-center p-2 bg-transparent text-white-gray w-full " + className}>
            <label className="p-1 cursor-pointer flex-1/2 min-w-[7rem] text-color5" htmlFor={id}>{label}</label>
            <div>{prefix}</div>
            <div className="w-full relative">
                <input
                    type={type ? type : 'text'}
                    id={id}
                    className={"outline-none bg-primary placeholder:italic placeholder:text-sm border border-0.5 border-gray-light focus:border-plant rounded p-2 flex-1 w-full h-auto " + className}
                    value={value}
                    placeholder={`Enter ${id}`}
                    onChange={onChange} />
                {children}
            </div>
        </div>
    )
}

export const ProfileTextArea = ({ type, label, id, value, onChange, className }) => {
    const textRef = useRef()
    const [textareaheight, setTextareaheight] = useState(3)

    function handleChange(event) {
        const height = event.target.scrollHeight
        const rowHeight = 25
        const trows = Math.ceil(height / rowHeight) - 1
        if (trows > textareaheight) {
            setTextareaheight(trows)
        }
        onChange(event)
    }

    useEffect(() => {

    }, [])
    return (
        <div
            className={"md:col-span-2 flex md:flex-row flex-col md:items-start p-2 bg-transparent text-white-gray w-full"}>
            <label
                className="p-1 cursor-pointer flex-1/2 min-w-[7rem] text-color5"
                htmlFor={id}>{label}</label>
            <textarea
                ref={textRef}
                rows={textareaheight}
                type={type ? type : 'text'}
                id={id}
                className={"textarea-ico outline-none bg-primary placeholder:italic placeholder:text-sm border border-0.5 border-gray-light focus:border-plant rounded p-2 flex-1 w-full" + className}
                value={value}
                placeholder={`Enter ${id}`}
                onChange={(e) => handleChange(e)} />
        </div>
    )
}

export const InputTime = ({ time, setTime }) => {
    const [isOpen, setIsOpen] = useState('')
    const [minutes, setMinutes] = useState([])
    const [hours, setHours] = useState([])
    // const [dayPeriod, setDayPeriod] = useState('AM')
    const containerRef = useRef()
    const selectedHourRef = useRef()
    const selectedMinuteRef = useRef()
    const noRef = useRef()

    const [selected, setSelected] = useState({ hour: '', minute: '', dayPeriod: '' })


    const convertTime = (value) => {
        if (time.length === 0) return { hour: '', minute: '', dayPeriod: '' }
        let recentTime = convert24to12(value)

        let hour = recentTime.split(':')[0]
        let minute = recentTime.split(':')[1].split(' ')[0]
        let period = recentTime.split(':')[1].split(' ')[1]

        return { hour: hour, minute: minute, dayPeriod: period }
    }

    useEffect(() => {
        const displayedTime = () => {
            let mins = []
            for (let i = 0; i <= 55; i += 5) {
                let stringValue = i.toString()
                if (stringValue.length === 1) stringValue = '0' + stringValue
                mins.push(stringValue)
            }

            setMinutes(mins)

            let hrs = []
            for (let i = 1; i <= 12; i++) {
                let stringValue = i.toString()
                if (stringValue.length === 1) stringValue = '0' + stringValue
                hrs.push(stringValue)
            }

            setHours(hrs)
        }

        displayedTime()
    }, [])

    useEffect(() => {
        setSelected(c => { return convertTime(time) })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (selectedMinuteRef.current && selectedHourRef.current && isOpen) {
            selectedMinuteRef.current.scrollIntoView({ block: 'center' })
            selectedHourRef.current.scrollIntoView({ block: 'center' })
        }
    }, [isOpen])

    const selectMinutes = (value) => {
        if (time === '') {
            setSelected({ ...selected, minute: value })
            return setTime(`01:${value}`)
        }

        let hrs = time.split(':')[0]
        let newTime = hrs + ':' + value
        setSelected({ ...selected, minute: value })
        setTime(newTime)
    }

    const selectHours = (value) => {
        if (time === '') {
            setSelected({ ...selected, hour: value })
            return setTime(`${value}:00`)
        }
        let recentTime = convertTime(time)
        let newValue = value
        if (recentTime.dayPeriod === 'PM') newValue = ((parseInt(value) % 12) + 12).toString()

        let newTime = newValue + ':' + recentTime.minute + ' ' + recentTime.dayPeriod
        setSelected({ hour: value, minute: recentTime.minute, dayPeriod: recentTime.dayPeriod })
        setTime(newTime)
    }

    const selectDayPeriod = (value) => {
        if (value === 'AM') {
            if (time === '') {
                setSelected({ ...selected, dayPeriod: 'AM' })
                return setTime('01:00')
            }

            let mins = time.split(':')[1]
            let hrs = parseInt(time.split(':')[0])
            hrs = hrs % 12
            hrs = hrs.toString()
            hrs = hrs.length === 1 ? '0' + hrs : hrs
            setTime(hrs + ':' + mins)
            // setDayPeriod('AM')
            setSelected({ ...selected, dayPeriod: 'AM' })
        }

        if (value === 'PM') {
            if (time === '') {
                setSelected({ ...selected, dayPeriod: 'PM' })
                return setTime('13:00')
            }
            let mins = time.split(':')[1]
            let hrs = parseInt(time.split(':')[0])
            hrs = (hrs % 12) + 12
            hrs = hrs.toString()
            hrs = hrs.length === 1 ? '0' + hrs : hrs
            setTime(hrs + ':' + mins)
            // setDayPeriod('PM')
            setSelected({ ...selected, dayPeriod: 'PM' })
        }

        setIsOpen(false)
    }

    useOutsideFinder(() => setIsOpen(false), containerRef)

    return (
        <>
            <div ref={containerRef} >
                <div
                    className={`h-8 p-2 flex items-center rounded-md bg-primary text-white w-full min-w-[5rem] md:cursor-pointer cursor-default ${isOpen ? 'border-color2 border-2' : 'border-gray-light border-2'}`}
                    onClick={(e) => { e.preventDefault(); setIsOpen(true) }}
                    required
                >
                    {time === '' ? time : convert24to12(cloneDeep(time))}
                </div>
                {isOpen && <div className='flex absolute h-44 z-30 text-black px-2 rounded-sm bg-white'>
                    <div className='overflow-auto'>
                        {hours.map((hour, key) => {
                            return (
                                <div ref={selected.hour === hour ? selectedHourRef : noRef} key={key} onClick={() => selectHours(hour)} className={`p-3 cursor-pointer ${selected.hour === hour ? 'bg-color8' : ''}`}>{hour}</div>
                            )
                        })}
                    </div>
                    <div className='overflow-auto'>
                        {minutes.map((minute, key) => {
                            return (
                                <div ref={selected.minute === minute ? selectedMinuteRef : noRef} key={key} onClick={() => selectMinutes(minute)} className={`p-3 cursor-pointer ${selected.minute === minute ? 'bg-color8' : ''}`}>{minute}</div>
                            )
                        })}
                    </div>
                    <div>
                        <div onClick={() => selectDayPeriod('AM')} className={`p-3 cursor-pointer ${selected.dayPeriod === 'AM' ? 'bg-color8' : ''}`}>AM</div>
                        <div onClick={() => selectDayPeriod('PM')} className={`p-3 cursor-pointer ${selected.dayPeriod === 'PM' ? 'bg-color8' : ''}`}>PM</div>
                    </div>
                </div>}
            </div>
        </>
    )
}

export const SearchInput = ({ id, type, value, onChange, onRemove, className = 'w-full' }) => {
    return (
        <div className={'relative w-full py-2 text-color5'}>
            <input type={type ? type : 'text'} placeholder={`Search a ${id ? id : 'value'}`} className={`outline-none bg-transparent border border-gray-light rounded p-2 pl-10 focus:border-gray-light placeholder:text-sm placeholder:italic placeholder:text-gray-light h-12 ${className}`} value={value} onChange={onChange} />
            <SearchIco className={'absolute top-1/2 -translate-y-1/2 left-2 -rotate-3'} />
            {value?.length > 0 ? <CloseIco className={'absolute top-1/2 -translate-y-1/2 right-2 -rotate-3 w-[2rem]'} onClick={onRemove} /> : null}
        </div>
    )
}

export const TagInput = ({ data }) => {
    const tagRef = useRef()
    const [isOpen, setIsOpen] = useState(false)
    const [tagsList, setTagsList] = useState(cloneDeep(data))
    const [selectedValue, setSelectedValue] = useState([])
    const [searchKey, setSearchKey] = useState('')


    const filteredData = tagsList?.filter(x => { return x.name.toLowerCase().includes(searchKey.toLowerCase()) })

    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    const handleOptionClick = (option) => {
        setSelectedValue([...selectedValue, option])
        setTagsList(tagsList.filter(x => x.id !== option.id))
    }

    const handleRemoveClick = (option) => {
        setTagsList([...tagsList, option])
        setSelectedValue(selectedValue.filter(x => x.id !== option.id))
    }

    useOutsideFinder(() => {
        setSearchKey('')
        setIsOpen(false)
    }, tagRef)

    useEffect(() => {
        setTagsList(cloneDeep(data))
    }, [data])

    return (
        <div className={"relative flex md:flex-row flex-col md:items-center p-2 bg-transparent text-white dark:text-color5 w-full"}>
            <label className="p-1 cursor-pointer flex-1/2 min-w-[7rem] text-color5">{'Tags'}</label>
            <div className="w-full overflow-auto">
                <div className="flex items-center border border-primary min-h-[2.5rem] rounded px-2 overflow-x-auto" onClick={toggleDropdown}>
                    {!selectedValue || selectedValue.length === 0 ? <span className="text-color5 text-sm italic">No selected tags</span> : null}
                    <div className="flex items-center overflow-x-auto w-full">
                        <div className="flex gap-1 items-center w-full overflow-x-auto p-1">
                            {
                                selectedValue
                                    .reverse()
                                    .map((option, index) => {
                                        return <div key={index} className="flex w-fit bg-color5 text-black rounded-md items-center px-1">
                                            <span className="whitespace-nowrap">{option.name}</span>
                                            <CloseIco className={' w-8 h-8 '} onClick={() => handleRemoveClick(option)} />
                                        </div>
                                    })
                            }
                        </div>
                        <span className="ml-2">
                            {/* <ChevronDown /> */}
                        </span>
                    </div>
                </div>
            </div>
            <div className={`w-full bg-black absolute overflow-auto rounded-none z-[30] top-full h-[20rem]  ${isOpen ? "" : "hidden"}`} ref={tagRef}>
                <div className="w-full px-3 py-2">
                    <SearchInput id={'search'} className="bg-primary w-full" value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                </div>
                <div className="flex flex-col bg-black text-color5">
                    {
                        filteredData && filteredData?.length > 0
                            ? filteredData.map((x, index) => {
                                return (<div key={index} className="w-full p-2 hover:bg-plant hover:text-secondary cursor-pointer" onClick={() => handleOptionClick(x)}>
                                    <span>{x.name}</span>
                                </div>
                                )
                            })
                            : null
                    }
                </div>
            </div>
        </div>
    )
}