import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import io from "socket.io-client";
import useData from "../hooks/useData";

const SocketClient = () => {
    const { store, setRefresh } = useData()

    const checkOrder = (id) => {
        const data = localStorage.getItem('orders_received')
        if (data) {

        } else {
            localStorage.setItem('orders_received', { order_id: id, read: false })
        }
    }

    useEffect(() => {
        if (!isMobile) {
            if (Notification.permission !== 'granted') {
                Notification.requestPermission().then(permission => {
                    if (permission !== 'granted') {
                        console.log('Notifications are not allowed by the user.');
                    }
                });
            }

            const socket = io.connect(process.env.REACT_APP_SOCKET_URL, { transports: ['websocket'], auth: { token: sessionStorage.getItem('ruser') }, })

            socket.on("order-update", (order) => {
                if (order.storeName === store.name) {
                    setRefresh(true)
                    const notification = new Notification('New Orders', {
                        body: `You have a new orders.`,
                    });
                    
                    notification.onclick = function () {
                        
                    };

                    notification.onclose = function () {
                        // Handle close event, if needed.
                    };
                }
            });

            // socket.emit("saveId", {});

            return () => {
                socket.disconnect();
            };
        }
        // eslint-disable-next-line
    }, []);

    return (
        <></>
    );
};

export default SocketClient;