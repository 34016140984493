import Toggle from './Toggle'
import useData from '../hooks/useData'
import { isBetween } from '../hooks/ConvertTime'

export const WorkingHours = ({ className }) => {
    const { workingHours, setWorkingHours } = useData()

    const handleChange = (w) => {
        const newState = workingHours.map(obj => {
            if (obj.day === w.day) {
                if (!w.enable)
                    return { ...obj, enable: !w.enable, start: '08:00', end: '21:00' }
                return { ...obj, enable: !w.enable, start: '', end: '' }
            }
            return obj
        })

        setWorkingHours(newState)
    }

    const handleStart = (w, e) => {
        const newState = workingHours.map(obj => {
            if (obj.day === w.day) {
                return { ...obj, start: e }
            }
            return obj
        })

        setWorkingHours(newState)
    }

    const handleEnd = (w, e) => {
        const newState = workingHours.map(obj => {
            if (obj.day === w.day) {
                return { ...obj, end: e }
            }
            return obj
        })

        setWorkingHours(newState)
    }

    return (
        <div className={`flex md:flex-row flex-col p-2 bg-transparent text-white dark:text-color5 ${className}`}>
            <div className="flex flex-col w-full py-3">
                {
                    workingHours
                        ? workingHours.map(w => {
                            console.log(w.day, isBetween(w.start, w.end))
                            return <Toggle key={w.day} name={w.day} enabled={w.enable} valueStart={w.start} valueEnd={w.end} onClickValue={() => handleChange(w)} onStartChange={(e) => handleStart(w, e)} onEndChange={(e) => handleEnd(w, e)} isDate={true} />
                        })
                        : null
                }
            </div>
        </div>
    )
}