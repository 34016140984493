import useData from "./useData";


export const ToDecimal = (number) => {
    const { decimalDigit } = useData()
    const digits = decimalDigit ? decimalDigit : 2
    if (number) {
        if (typeof (number) === 'number') {
            const roundedNumber = Math.round(number * 100) / 100;
            return roundedNumber
        }
        return 0
    }
    return 0
}

export const GenerateUniqueId = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(0, 5);
}