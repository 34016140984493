import { useEffect } from "react"
import useData from "../hooks/useData"
import '../assets/css/deleteAccount.css'

export default function Delete() {
    const { hideSplash } = useData()

    useEffect(() => {
        hideSplash()
    })

    return (
        <div className="account-deletion-container bg-primary text-white-gray overflow-auto">
            <h1 className="deletion-heading text-white">How to Request Account Deletion on Rapid</h1>

            <div className="deletion-section">
                <h2>Introduction:</h2>
                <p>
                    If you're looking to delete your account on the Rapid app, we've outlined
                    the steps for you. Your privacy and data security are important to us,
                    and we want to ensure a smooth process for account deletion while
                    maintaining necessary record-keeping requirements.
                </p>
            </div>

            <div className="deletion-section">
                <h2>Step 1: Send an Email to Request Deletion</h2>
                <p>
                    Compose an email from your preferred email account.
                    <br />
                    Address the email to <a href="mailto:rd.lebanon@gmail.com">rd.lebanon@gmail.com</a>
                </p>
            </div>

            <div className="deletion-section">
                <h2>Step 2: Provide Request Details</h2>
                <p>
                    In the subject line of the email, write "Account Deletion Request for Rapid App.”
                    <br />
                    In the body of the email, provide the required details to initiate the account deletion request. These details are necessary to review and process your request securely.
                </p>
                <ul>
                    <li>Full Name: Enter your full legal name.</li>
                    <li>Phone Number: Provide the phone number associated with your account.</li>
                    <li>Email: Enter the email address linked to your account.</li>
                    <li>Last 3 Orders: Specify information related to your last three orders, such as order number or purchase details.</li>
                    <li>Government ID: Attach a clear image or scan of your government-issued identification (Front/Back). This is required for identity verification.</li>
                </ul>
            </div>

            <div className="deletion-section">
                <h2>Step 3: Verification Process</h2>
                <p>
                    After sending the email, our team may contact you for further verification to ensure you are the rightful owner of the account. This step is essential for security purposes.
                    <br />
                    We may reach out to you via your email or phone number if the provided information is found to be incompatible or requires further clarification.
                </p>
            </div>

            <div className="deletion-section">
                <h2>Step 4: Data Deletion and Retention</h2>
                <p>
                    Once your request is verified, we will proceed to delete the following data associated with your account:
                </p>
                <ul>
                    <li>Full Name</li>
                    <li>Phone Number</li>
                    <li>User-added addresses (Location)</li>
                </ul>
                <p>
                    We will retain other data for a specified retention period. This may include order history for accounting and legal purposes. The retention period will be in accordance with applicable laws and regulations.
                    <br />
                    Rest assured that we take data privacy seriously, and any retained data will be handled securely and in compliance with our privacy policy.
                </p>
            </div>

            <div className="deletion-section">
                <h2>Conclusion:</h2>
                <p>
                    By following these steps, you can request the deletion of your account on the Rapid App by sending an email to <a href="mailto:rd.lebanon@gmail.com">rd.lebanon@gmail.com</a>. This ensures the removal of sensitive personal information while adhering to data retention requirements. Your privacy is important to us, and we are committed to safeguarding your data.
                </p>
            </div>
        </div>
    )
}