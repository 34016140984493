import { Margins } from '../conponents/Margins'
import { CentralContainer, ContainerFooter, ContainerHeader } from '../conponents/Containers'
import { WorkingHours } from '../conponents/WorkingHours'
import { useParams } from 'react-router-dom'
import useData from '../hooks/useData'
import { useEffect } from 'react'

export default function WorkingHour() {
    const { username } = useParams()
    const { profileAction, hideSplash, displayToast } = useData()

    const handleUpdate = () => {
        profileAction('UPDATE_HOURS')
            .then(response => {
                const { status, data, err } = response
                if (status)
                    displayToast(data)
                else
                    displayToast(err)
            })
            .catch(err => displayToast(err))
    }

    useEffect(() => {
        hideSplash(1200)
        // eslint-disable-next-line
    }, [])
    return (
        <Margins>
            <CentralContainer>
                <ContainerHeader username={username} page={'Working Hours'} showBack={true} />
                <WorkingHours className={'flex-grow'} />
                <ContainerFooter text={'Save'} onClick={handleUpdate} />
            </CentralContainer>
        </Margins>
    )
}