import { InputTime } from "./Input";

export default function Toggle({ enabled, onClickValue, name, onStartChange, onEndChange, valueStart, valueEnd, isDate }) {

    return (
        <div className="flex md:flex-row flex-col text-sm my-2 h-8">
            <label className="inline-flex relative items-center mr-5 cursor-pointer">
                <input type="checkbox" className="sr-only peer border" checked={enabled} readOnly />
                <div onClick={onClickValue} className="w-9 h-6 bg-color5 rounded-full peer peer-focus:ring-green peer-checked:after:translate-x-full peer-checked:after:border-color2 after:content-[''] after:absolute after:top-1/2 after:-translate-y-1/2 after:left-[2px] after:bg-color2 after:border after:border-color5 after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green"></div>
                <span className="px-2 nowhitespace font-medium w-14">
                    {name}
                </span>
            </label>
            {
                enabled
                    ? isDate
                        ? <div className="flex items-center">
                            <label className="px-3">Open</label>
                            <InputTime className={'border '} time={valueStart === valueEnd ? '' : valueStart} setTime={onStartChange} />
                            <label className="px-3">Close</label>
                            <InputTime className={'border '} time={valueEnd === valueStart ? '' : valueEnd} setTime={onEndChange} />
                        </div>
                        : null
                    : null
            }
        </div>
        // </div>
    );
}