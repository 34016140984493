import { BrowserRouter, Route, Routes } from "react-router-dom"
import Authentication from './hooks/Authentication'
import Login from './pages/Login'
import Home from './pages/Home'
import Profile from './pages/Profile'
import Menu from "./pages/Menu"
import MenuList from "./pages/MenuList"
import Setting from "./pages/Setting"
import Group from "./pages/Group"
import { DataProvider } from "./context/DataProvider"
import WorkingHour from "./pages/WorkingHour"
import PriceList from "./pages/PriceList"
import OrderList from "./pages/OrderList"
import OrderDetails from "./pages/OrderDetails"
import Report from "./pages/Report"
import Privacy from "./pages/Privacy"
import Support from "./pages/Support"
import Delete from "./pages/Delete"
import Payments from "./pages/Payments"

const AppRouting = () => {
    return (
        <BrowserRouter>
            <DataProvider>
                <Routes>
                    <Route exact path='/Login' element={<Login />} />
                    <Route exact path='/Privacy' element={<Privacy />} />
                    <Route exact path='/Support' element={<Support />} />
                    <Route exact path='/DeleteAccount' element={<Delete />} />
                </Routes>
                <Routes>
                    <Route element={<Authentication />}>
                        <Route exact path='/' element={<Home />} />
                    </Route>
                </Routes>

                <Routes>
                    <Route element={<Authentication />}>
                        <Route exact path='/Store/:username' element={<Home />} />
                        <Route exact path='/Store/:username/profile' element={<Profile />} />
                        <Route exact path='/Store/:username/addmenu' element={<Menu />} />
                        <Route exact path='/Store/:username/listmenu' element={<MenuList />} />
                        <Route exact path='/Store/:username/setting' element={<Setting />} />
                        <Route exact path='/Store/:username/group' element={<Group />} />
                        <Route exact path='/Store/:username/workinghours' element={<WorkingHour />} />
                        <Route exact path='/Store/:username/pricelist' element={<PriceList />} />
                        <Route exact path='/Store/:username/order' element={<OrderList />} />
                        <Route exact path='/Store/:username/orderdetails' element={<OrderDetails />} />
                        <Route exact path='/Store/:username/report' element={<Report />} />
                        <Route exact path='/Store/:username/payments' element={<Payments />} />
                    </Route>
                </Routes>
            </DataProvider>
        </BrowserRouter>
    )
};
export default AppRouting;