import { Fragment, Suspense, useEffect, useState } from "react"
import useData from "../hooks/useData"
import { SearchInput } from "./Input"
import { useNavigate } from "react-router-dom"
import { ListSpalsh } from "./Splash"

const TabelCell = ({ x }) => {
    const { store } = useData()
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    return (
        <Suspense key={x.id} fallback={<ListSpalsh />}>
            <div className="flex justify-center md:w-[20rem] w-full min-w-[20rem] items-center p-1" onClick={() => navigate(`/Store/${store.name}/addmenu/`, { state: { id: x.id } })}>
                <div className="flex bg-black w-full h-[8rem] p-2 relative cursor-pointer">
                    <div className="flex-grow flex flex-col items-start">
                        <span className="font-semibold h-6 text-white-gray">{x.name ? x.name : 'No title specified'}</span>
                        <span className="flex-grow text-sm italic h-8 text-ellipsis overflow-hidden px-2">{x.description ? x.description : 'No description'}</span>
                        <div className={'flex'}>
                            <span className={`font-semibold px-1 h-6 ${(x.discount > 0 ? 'text-color5 line-through' : 'text-plant')}`}>{x.price ? x.price.toFixed(2) : 0} {store.currency}</span>
                            {x.discount > 0 && <span className="font-semibold px-1 h-6 text-plant">{x.discount && x.discount > 0 ? (x.price - (x.price * x.discount / 100)).toFixed(2) : ''} {store.currency}</span>}
                        </div>
                    </div>
                    <div className="flex w-24 items-center justify-center rounded-md p-1">
                        {loading && <ListSpalsh />}
                        <img onLoad={() => setLoading(false)} className={"w-[6rem] h-[6rem] min-w-[6rem] text-center items-center rounded-md " + (loading ? 'hidden' : '')} src={`${x.image}`} alt="" />
                    </div>
                </div>
            </div>
        </Suspense>
    )
}

export const Table = ({ selectedCat }) => {
    const { displayToast, hideSplash, productAction } = useData()
    const [productList, setProductList] = useState([])
    const [searchKey, setSearchKey] = useState('')
    const filtered = selectedCat > 0 ? productList.filter(x => { return x.category_id === selectedCat }) : productList
    const filteredKey = filtered.filter((x => { return x.name?.toLowerCase().includes(searchKey?.toLowerCase()) }))
    // console.log(selectedCat)

    useEffect(() => {
        productAction('GET')
            .then(async (response) => {
                const { status, data, err } = response
                // console.log(response)
                if (status) {
                    await setProductList(data)
                    setTimeout(() => hideSplash(), 1200)
                } else {
                    displayToast(err)
                    setTimeout(() => hideSplash(), 1200)
                }
            })
            .catch(err => displayToast(err))
        hideSplash()
        // eslint-disable-next-line
    }, [])

    return (
        <div className="flex flex-col w-full overflow-y-auto">
            <SearchInput className="md:w-[25%]" id={'Items'} value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
            <div className="flex flex-wrap justify-center mx-auto">
                {filteredKey ? (
                    filteredKey.length > 0 ? (
                        filteredKey.map((x) => {
                            return (
                                <TabelCell key={x.id} x={x} />
                            )
                        })
                    ) : (
                        <span className="flex w-full justify-center items-center italic text-sm">No data to show</span>
                    )
                ) : null}
            </div>
        </div>

    )
}

